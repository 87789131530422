
import { Component, Vue } from "vue-property-decorator";
import { LoyaltyPointsTranactionReturnsAdmin, Workshops } from "@/network/api";
import { LoyaltyPointsTranactionReturnDto, WorkshopViewModel } from "@/api-client";
import Pagination from "@/components/Pagination.vue";
import { formatDate } from "@/utils/formatDate";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue"
import handleError from "@/utils/handleError";
import sort from "@/utils/sort";

@Component({
  name: "ReturnsTable",
  props: {},
  components: {
    Pagination,
    BasicInputField,
    BasicSelectorField
  },
})
export default class extends Vue {
  tableData: Array<LoyaltyPointsTranactionReturnDto> = [];
  status: number = 0;
  isLoading = false;
  currentPage = 1;
  pageSize = 20;
  totalPages = 0;
  totalCount = 0;
  searchTerm: string = "";
  workshopFilterId: string = "";
  workshopDdl: Array<WorkshopViewModel> = [];

  async created() {
    this.loadInitialData();
  }

  displayDate(date: string | null | undefined) {
    return formatDate(date);
  }

  handleSearch(input = false) {
    this.currentPage = 1;
    this.loadInitialData().then(() => {
      if(input) {
        let searchElem: any = this.$refs.searchInput;
        searchElem!.focus();
      }
    });
  }

  handlePageNumberClick(page: any) {
    this.currentPage = page;
    this.loadInitialData();
  }

  async loadInitialData(): Promise<any> {
    this.isLoading = true;
    window.scrollTo({ top: 0 });

    await LoyaltyPointsTranactionReturnsAdmin.apiLoyaltyPointsReturnsAdminGet(this.currentPage, this.pageSize, this.workshopFilterId || undefined, this.searchTerm || undefined)
      .then((res) => {
        if (res.data) {
          this.isLoading = false;
          this.tableData = res.data.data as LoyaltyPointsTranactionReturnDto[];
          this.totalPages = res.data.pageCount as number;
          this.totalCount = res.data.totalCount as number;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        handleError(error)
      });
  }

  getWorkshops(value:string) {
    Workshops.apiWorkshopsGet(1, 30, value)
      .then((res) => {
        if (res.data) {
          this.workshopDdl = sort(res.data.data, 'name') as WorkshopViewModel[];
        }
      })
      .catch((error) => {
        this.isLoading = false;
        handleError(error)
      });
  }

  handleWorkshops(open: boolean) {
    if(open) {
      this.workshopDdl = []
    }
  }

  filterWorkshops(value: string) {
    if(value.length >= 3) {
      this.getWorkshops(value)
    } else {
      this.workshopDdl = []
    }
  }
}
