
import Logo from "@/components/Logo/index.vue";
import variables from "@/styles/_variables.scss";
import { Component, Vue } from "vue-property-decorator";
import { hasClaim } from "@/utils/applicationClaims";
import WorkshopPointSummary from '@/components/rewards-module/WorkshopPointSummary.vue'
import store from "@/store";
import { Workshops, LoyaltyPointTransactions } from "@/network/api"
import { ApplicationClaimType } from "@/api-client";
import handleError from "@/utils/handleError";

@Component({
  name: "LandingPage",
  components: {
    Logo,
    WorkshopPointSummary
  },
})
export default class extends Vue {
  authorisedModules: any[] = [];
  key = 100;
  pointSummary: any = {
    name: null,
    total: null,
    totalExpiringSoon: null
  };
  inactiveWorkshop = false;
  canViewPointSummary = false;

  get variables() {
    return variables;
  }

  get workshopId() {
    return store.getters["user/workshopId"];
  }

  get brandImg() {
    return [
      {
        title: "GUD Filters brand logo",
        src: "gud-filters.svg"
      },
      {
        title: "FRAM brand logo",
        src: "fram.svg"
      },
      {
        title: "Safeline brand logo",
        src: "safeline.svg"
      },
      {
        title: "Indy Oil brand logo",
        src: "indy-oil.svg"
      },
      {
        title: "Holts brand logo",
        src: "holts.svg"
      },
      {
        title: "RAW 4x4 brand logo",
        src: "raw4x4.svg"
      }
    ]
  }

  get modules() {
    return [
      {
        // workshop user only
        title: "Fit & Earn",
        image: "icon/rewards.svg",
        link: {
          destination: { name: "RewardsModule" },
          isWorkshopScoped: true,
          claim: ["ViewClaims", "ManageClaims", "ViewReturns", "ManageReturns"], // workshop user only
        }
      },
      {
        // admin user only
        title: "Fit & Earn",
        image: "icon/rewards.svg",
        link: {
          destination: { name: "RewardPointList" },
          isWorkshopScoped: false,
          claim: ["ViewClaims", "ManageClaims", "ViewReturns", "ManageReturns"], // workshop user only
        }
      },
      {
        // admin user only
        title: "Rewards Product Management",
        image: "icon/rewardsmanagement.svg",
        link: {
          destination: { name: "RewardProductList" },
          isWorkshopScoped: false,
          claim: ["ViewProducts", "ManageProducts"],
        }
      },
      {
        // workshop user only
        title: "Rewards Store",
        image: "icon/trophystar.svg",
        link: {
          destination: `${process.env.VUE_APP_ROOT_REWARDS}/login`,
          isWorkshopScoped: true,
          claim: ["ViewRewardsStore"],
        }
      },
      {
        // visible to all
        title: "Product Finder",
        image: "icon/filter.svg",
        link: {
          destination: { name: "ProductFinder" },
          isWorkshopScoped: false,
          claim: [],
          // targetBlank: true
        }
      },
      {
        // workshop user only
        title: "Promotions",
        image: "icon/prizeribbon.svg",
        link: {
          destination: { name: "Promotions" },
          isWorkshopScoped: true,
          claim: ["ViewBulletins"],
          // targetBlank: true
        }
      },
      {
        // workshop user only
        title: "Bulletins",
        image: "icon/document.svg",
        link: {
          destination: { name: "Bulletins" },
          isWorkshopScoped: true,
          claim: ["ViewBulletins"],
          // targetBlank: true
        }
      },
      {
        // admin user only
        title: "Bulletin Management",
        image: "icon/documentcog.svg",
        link: {
          destination: { name: "AdminPromotionsBulletins" },
          isWorkshopScoped: false,
          claim: ["ViewBulletins", "ManageBulletins"],
        }
      },
      {
        // workshop user only
        title: "Workshop Management",
        image: "icon/cogs.svg",
        link: {
          destination: { name: "WorkshopDetailWorkshopUser", params: { id: this.workshopId } },
          isWorkshopScoped: true,
          claim: ["ViewWorkshops", "ManageWorkshops", "ViewUsers", "ManageUsers"],
        }
      },
      {
        // admin user only
        title: "Workshop Management",
        image: "icon/cogs.svg",
        link: {
          destination: { name: "WorkshopManagement"},
          isWorkshopScoped: false,
          claim: ["ViewWorkshops", "ManageWorkshops", "ViewUsers", "ManageUsers"],
        }
      },
      {
        // admin user only
        title: "User Management",
        image: "icon/usercog.svg",
        link: {
          destination: { name: "UserManagement" },
          isWorkshopScoped: false,
          claim: ["ViewUsers", "ManageUsers"],
        }
      },
      {
        // admin user only
        title: "Role Management",
        image: "icon/rolecog.svg",
        link: {
          destination: { name: "RoleManagement" },
          isWorkshopScoped: false,
          claim: ["ViewUsers", "ManageUsers"],
        }
      },
      {
        // admin user only
        title: "Audit Logs",
        image: "icon/document.svg",
        link: {
          destination: { name: "AuditLogs" },
          isWorkshopScoped: false,
          claim: ["ViewAuditLogs", "ManageAuditLogs"],
        }
      },
    ]
  }

  async created() {
    this.canViewPointSummary = await hasClaim('ViewClaims', true) || await hasClaim('ManageClaims', true)
    this.getAuthorisedModules();

    this.$watch("workshopId", () => {
      this.getAuthorisedModules();
    });
  }

  async hasClaimFunction(link: any) {
    if (!link.claim.length) {
      return true;
    } else {
      let has = false;
      if(link.claim.length > 1) {
        for(let i = 0; i < link.claim.length ;i++) {
          has = await hasClaim(link.claim[i], link.isWorkshopScoped);
          if(has) {
            break;
          }
        }
      } else {
        has = await hasClaim(link.claim[0], link.isWorkshopScoped);
      } 
      return has;
    }
  }

  async getAuthorisedModules() {
    let modulesByClaimAccess: Array<any> = []
    for (let i = 0; i < this.modules.length; i++) {
      const isClaim = await this.hasClaimAccess(this.modules[i]) != undefined
      if(isClaim) {
        modulesByClaimAccess.push(this.modules[i])
      }
    }
    this.authorisedModules = modulesByClaimAccess || [];
    this.key += 1;
    this.getPointSummary();
  }

  async getWorkshopName(): Promise<any> {
    if(!this.workshopId) return false;
    Workshops.apiWorkshopsForUserGet(1, 999999)
      .then((res) => {
        if (res.data) {
          const workshop = res.data.data.find(item => item.id == this.workshopId)
          if(workshop) {
            this.pointSummary.name = workshop.name;
            if(workshop.workshopStatusId == 'Inactive') {
              this.inactiveWorkshop = true;
            }
          } else {
            this.inactiveWorkshop = true;
          }
        }
      })
      .catch((error) => {
        if(error?.request?.status == 403) {
          this.inactiveWorkshop = true;
        } else {
          handleError(error, true, "An error occurred trying to retrieve workshop info.", 4000)
        }
      })
  }

  async getWorkshopPoints(): Promise<any> {
    if(!this.workshopId) return false;
    await LoyaltyPointTransactions.apiLoyaltyPointTransactionsBalanceGet(this.workshopId!)
      .then((res) => {
        if (res.data) {
          this.pointSummary.total = res.data.total;
          this.pointSummary.totalExpiringSoon = res.data.totalExpiringSoon;
        }
      })
      .catch((error) => {
        if(error?.request?.status !== 403) {
          handleError(error, true, "An error occurred trying to retrieve workshop points.", 4000)
        }
      })
  }

  isPointSummary() {
    return this.pointSummary.name != null && this.pointSummary.total != null && this.pointSummary.totalExpiringSoon != null;
  }

  async getPointSummary() {
    this.pointSummary = {
      name: null,
      total: null,
      totalExpiringSoon: null
    };
    this.getWorkshopName();
    this.getWorkshopPoints();
  }

  async hasClaimAccess(element: any) {
    const hasAccess = await this.hasClaimFunction(element.link);
    if (hasAccess) {
      return element.link;
    }
  }

  isExternal(dest: string) {
    if (!dest) {
      return false;
    }
    return typeof dest === "string" ? dest.indexOf("http") > -1 : false;
  }
}
