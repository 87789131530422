
import { Component, Vue } from "vue-property-decorator";
import { formatDate } from "@/utils/formatDate";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate";

@Component({
  name: "BasicDateField",
  components: { ValidationProvider, ValidationObserver },
  props: {
    label: { default: '' },
    model: { default: '' },
    span: { default: 12 },
    sm: { default: 12 },
    xs: { default: 24 },
    rules: { default: '' },
    mode: { default: 'eager' },
    name: { default: '' },
  }
})
export default class extends Vue {
  selectedDates: any = null

  created () {
    this.selectedDates = this.$props.model
  }

  public pickerOptions = {
    firstDayOfWeek: 1,
    shortcuts: [{
      text: 'Last week',
      onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last month',
      onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: 'Last 3 months',
      onClick(picker: any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  }

  handleDate(date:any) {
    return formatDate(date)
  }

  handleDateRanges(value:any) {
    let dates = {};
    if(value) {
      let d1 = new Date(value[0])
      let d2 = new Date(value[1])

      dates = {
        startDate: new Date(d1.toDateString() + " GMT+0000").toISOString(),
        endDate: new Date(d2.toDateString() + " GMT+0000").toISOString()
      }
    } else {
      dates = {
        startDate: undefined,
        endDate: undefined
      };
    }  
    this.$emit('updateDate', dates)
  }
}
