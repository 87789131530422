
import { Component, Vue } from 'vue-property-decorator'
import { Role } from '@/network/api';
import DialogBox from "@/components/DialogBox.vue"
import Pagination from "@/components/Pagination.vue";
import { RoleListViewModel } from '@/api-client';
import { hasClaim } from '@/utils/applicationClaims';
import handleError from '@/utils/handleError';
  
  @Component({
    name: 'RoleList',
    components: {
      DialogBox,
      Pagination
    }
  })
  export default class extends Vue {
    tableData: Array<RoleListViewModel> = []
    search: string = ''
    isLoading = false
    dialogVisible: boolean = false
    dialogMessage: string = ""
    confirmText: string = "Ok"
    cancelVisible = true
    deletePendingIndex = ""
    currentPage = 1
    pageSize = 20
    totalPages = 0
    totalCount = 0
    canHandleClaim = {
      manage: false
    }

    async created() {
      this.canHandleClaim.manage = await hasClaim('ManageUsers', false);
      this.loadInitialData()
    }

    handlePageNumberClick(page: any) {
      this.currentPage = page;
      this.loadInitialData();
    }

    async loadInitialData(): Promise<any> {
      this.isLoading = true
      window.scrollTo({ top: 0 })
      await Role.apiRolesGet(this.currentPage, this.pageSize, this.search)
        .then((res) => {
          if (res.data) {
            this.isLoading = false
            this.tableData = res.data.data as RoleListViewModel[]
            this.totalPages = res.data.pageCount as number
            this.totalCount = res.data.totalCount as number
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.dialogVisible = false
          handleError(error)
        })
    }

    deleteData(id: string) {
      // Role.apiRolesIdDelete(id)
      //   .then((res) => {
      //     if (res.status > 199 && res.status < 300) {
      //       this.loadInitialData()
      //       this.isLoading = false
      //       // this.tableData = res.data
      //     }
      //   })
      //   .catch((error) => {
      //     this.isLoading = false
      //     this.dialogVisible = false
      //     handleError(error)
      //   })
    }

    deleteConfirm(id: string, name: string) {
      this.confirmText = "Yes"
      this.dialogMessage = `<span>Are you sure you want to remove the "${name}" from the system?</span>`
      this.dialogVisible = true
      this.deletePendingIndex = id
    }

    deleteHandle() {
      this.dialogVisible = false
      if (this.confirmText === "Yes") {
        this.deleteData(this.deletePendingIndex)
      } else {
        this.deletePendingIndex = ""
      }
    }
  }
