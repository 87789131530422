
import { Component, Vue } from 'vue-property-decorator'
import { BulletinsAdmin, Role } from '@/network/api';
import Pagination from "@/components/Pagination.vue";
import { BulletinDto, BulletinType, RoleListViewModel } from '@/api-client';
import { hasClaim } from '@/utils/applicationClaims';
import { formatDate } from '@/utils/formatDate';
import handleError from '@/utils/handleError';
import DialogBox from "@/components/DialogBox.vue"
  
  @Component({
    name: 'RoleList',
    components: {
      Pagination,
      DialogBox
    }
  })
  export default class extends Vue {
    tableData: Array<BulletinDto> = []
    search: string = ''
    isLoading = false
    dialogVisible: boolean = false
    dialogMessage: string = ""
    deletePendingIndex = ""
    currentPage = 1
    pageSize = 20
    totalPages = 0
    totalCount = 0
    typeFilter: BulletinType | "" = "";
    typesDdl: Array<{id: BulletinType, name: string}> = [
      {
        id: 'Bulletin',
        name: 'Bulletin'
      },
      {
        id: 'Promotion',
        name: 'Promotion'
      }
    ]
    canHandleClaim = {
      manage: false
    }

    get uploadPath() {
      return `${process.env.VUE_APP_ROOT_API}/files`;
    }

    async created() {
      this.canHandleClaim.manage = await hasClaim('ManageUsers', false);
      this.loadInitialData()
    }

    handleDate(date:any) {
      return formatDate(date);
    }

    handlePageNumberClick(page: any) {
      this.currentPage = page;
      this.loadInitialData();
    }

    handleSearch() {
      this.currentPage = 1;
      this.loadInitialData() 
    }

    deleteData() {
      if(this.deletePendingIndex) {
        this.isLoading = true
        BulletinsAdmin.apiBulletinsAdminIdDelete(this.deletePendingIndex)
          .then((res) => {
            if (res.status > 199 && res.status < 300) {
              this.isLoading = false
              this.dialogVisible = false
              this.deletePendingIndex = ""
              this.$message.success('Bulletin deleted.')
              this.loadInitialData()
            }
          })
          .catch((error) => {
            this.isLoading = false
            this.dialogVisible = false
            handleError(error)
          })
      }
    }

    deleteConfirm(item:BulletinDto) {
      this.dialogMessage = `<span>Are you sure you want to remove "${item.title}" from the system?</span>`
      this.dialogVisible = true
      this.deletePendingIndex = item.id
    }

    async loadInitialData(): Promise<any> {
      this.isLoading = true
      window.scrollTo({ top: 0 })
      await BulletinsAdmin.apiBulletinAdminGet(this.typeFilter || undefined, this.currentPage, this.pageSize)
        .then((res) => {
          if (res.data) {
            this.isLoading = false
            this.tableData = res.data.data as BulletinDto[]
            this.totalPages = res.data.pageCount as number
            this.totalCount = res.data.totalCount as number
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.dialogVisible = false
          handleError(error)
        })
    }
  }
