
import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import DialogBox from "@/components/DialogBox.vue";
import { Role } from "@/network/api";
import { RoleViewModel } from "@/api-client";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import { hasClaim } from "@/utils/applicationClaims";
import handleError from "@/utils/handleError";

@Component({
  name: "RoleDetail",
  props: {
    id: { default: "" },
  },
  components: {
    BasicInputField,
    BasicSelectorField,
    BasicSwitchField,
    ValidationProvider,
    ValidationObserver,
    DialogBox,
  },
})
export default class extends Vue {
  isLoading = true;
  formSubmit = false;
  displayName = "";
  role: RoleViewModel = {
    id: "",
    name: "",
    claims: [],
    isWorkshopRole: false,
    description: null,
  };
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible = true;
  claimsDdl: Array<{ id: string; name: string }> = [];
  canHandleClaim = {
    manage: false,
  };

  async created() {
    this.canHandleClaim.manage = await hasClaim("ManageUsers", false);

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });

    this.$watch("$props.id", () => {
      if (this.$props.id === "") {
        this.role = {
          id: "",
          name: "",
          claims: [],
          isWorkshopRole: false,
          description: null,
        };
      }
    });
  }

  mounted() {
    this.isLoading = false;
    this.loadInitialData();
  }

  loadInitialData() {
    this.getClaims();

    if (this.$props.id != "") {
      this.isLoading = true;
      Role.apiRolesIdGet(this.$props.id)
        .then((res) => {
          if (res.data) {
            this.isLoading = false;
            this.dialogVisible = false;
            this.role = res.data;
            this.displayName = this.role.name as string;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.dialogVisible = false;
          handleError(error);
        });
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.$router.push({ name: "RoleManagement" });
    } else {
      this.submitForm();
    }
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  clearDirtyClasses() {
    let dirty = document.querySelectorAll(".dirty");

    if (dirty.length) {
      for (let item of dirty) {
        item.classList.remove("dirty");
      }
    }
  }

  submitForm() {
    this.formSubmit = true;
    if (this.role.id != "") {
      this.updateRole();
    } else {
      this.createRole();
    }
  }

  createRole() {
    Role.apiRolesPost(this.role)
      .then((res) => {
        if (res.data.value) {
          this.$message.success("Saved!");
          this.role.id = res.data.value;
          this.formSubmit = false;
          this.dialogVisible = false;
          this.$router.push({ name: "RoleDetail", params: { id: res.data.value } });
          this.displayName = this.role.name as string;
          this.clearDirtyClasses();
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error);
      });
  }

  updateRole() {
    Role.apiRolesIdPut(this.role.id as string, this.role)
      .then((res) => {
        this.$message.success("Saved!");
        this.formSubmit = false;
        this.dialogVisible = false;
        this.loadInitialData();
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error);
      });
  }

  getClaims() {
    Role.apiClaimsGet(1, 99999)
      .then((res) => {
        if (res.data) {
          this.claimsDdl = Object.values(res.data.data as RoleViewModel[]).map((data: any) => {
            return { id: data.id, name: data.name };
          });
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.dialogVisible = false;
        handleError(error);
      });
  }
}
