
import { Component, Vue, Watch } from "vue-property-decorator";
import { LoyaltyPointsClaims, LoyaltyPointsClaimsAdmin, Product } from "@/network/api";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import DialogBox from "@/components/DialogBox.vue";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import InvoicePreview from "@/components/rewards-module/InvoicePreview.vue";
import { hasClaim } from "@/utils/applicationClaims";
import handleError from "@/utils/handleError";
import getFileExtension from "@/utils/getFileExtension";
import { LoyaltyPointClaimViewModel, ReturnsStatus, ReviewLoyaltyPointsClaimAdminCommand } from "@/api-client";
import sort from "@/utils/sort";

type ProductDdl = {
  id: string;
  name: string;
  value:string;
  sku: string;
}

@Component({
  name: "ReviewClaim",
  props: {
    id: { default: "" },
  },
  components: {
    InvoicePreview,
    BasicInputField,
    BasicDateField,
    BasicSelectorField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class extends Vue {
  isLoading = true;
  formSubmit = false;
  validImgExtensions = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp']
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible = true;
  productDdl: ProductDdl[] = [];
  displayInvoiceNumber: string = "";
  duplicateClaimNumber: any = false;
  claim: LoyaltyPointClaimViewModel = {
    id: "",
    invoiceRelativeUrl: "",
    invoiceNumber: "",
    invoiceDate: "",
    loyaltyPointsClaimStatusId: ReturnsStatus.Pending,
    reviewComment: "",
    reviewedByUserId: "",
    reviewedDate: "",
    workshopId: "",
    submittedByUserId: "",
    loyaltyPointsClaimStatusName: "",
    reviewedByUserDisplayName: "",
    submittedByUserDisplayName: "",
    products: [
      {
        quantity: 0,
        value: 0,
        productSku: "",
        productName: "",
        productId: "",
      },
    ],
    workshopName: "",
    totalValue: 0,
    dateSubmitted: "",
  };
  totalOverrideValue = 0

  canHandleClaim = {
    manage: false,
  };
  claimErrorCodes: Array<{ title: string, value: string }> = [
    {
      title: "Invoice detail unclear",
      value: "The invoice you recently uploaded onto ServiceBay unfortunately cannot be processed as we cannot identify a valid G.U.D. Holdings product part number. This may be because the image is blurry or the part number is cut off or obscured."
    },
    {
      title: "No valid GUD part number",
      value: "The invoice you recently uploaded onto ServiceBay unfortunately cannot be processed and redeemed for points as it does not contain a valid G.U.D. Holdings product part number. Please review the invoice and ensure you have uploaded the correct document. You may also need to check the invoice to identify that the supplier has used a valid part number on your invoice."
    },
    {
      title: "Invoice already submitted",
      value: "The invoice you recently uploaded onto ServiceBay unfortunately cannot be processed and redeemed for points as the invoice number has already been submitted previously. Please review the invoice and ensure you have uploaded the correct document."
    },
  ];

	get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get fileExtension() {
    if(this.claim.invoiceRelativeUrl) {
      return getFileExtension(this.claim.invoiceRelativeUrl)
    }
    return ""
  }


  async created() {
    this.canHandleClaim.manage = await hasClaim("ManageClaims", false);
    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  mounted() {
    this.isLoading = false;
    this.loadInitialData();
  }

  @Watch("claim.invoiceDate")
  refreshProductPoints() {
    if (!this.claim.products || this.claim.products.length < 1) {
      return false;
    }
    this.claim.products.forEach(async (product: any, index: number) => {
      this.claim.products[index].value = await this.getProductPoints(product.productId);
    });
  }

  get isApprovedDisabled() {
    return (this.claim.products != undefined && this.claim.products.length < 1) || this.formSubmit;
  }

  async loadInitialData() {
    if (this.$props.id != "") {
      this.isLoading = true;
      await LoyaltyPointsClaims.apiLoyaltyPointsClaimsIdGet(this.$props.id)
        .then((res) => {
          if (res.data) {
            this.isLoading = false;
            this.claim = res.data;
            if(this.claim.invoiceNumber) {
              this.displayInvoiceNumber = this.claim.invoiceNumber;
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          handleError(error);
        });
      this.claim.id = this.$props.id;
    }
  }

  scrollTopTable() {
    this.$nextTick(() => {
      document.getElementById("productSelect")!.scrollIntoView({ behavior: "smooth" });
    });
  }

  submitForm() {
    this.formSubmit = true;

    const payload: ReviewLoyaltyPointsClaimAdminCommand = {
      id: this.claim.id,
      invoiceDate: this.claim.invoiceDate,
      invoiceNumber: this.claim.invoiceNumber,
      loyaltyPointsClaimStatusId: this.claim.loyaltyPointsClaimStatusId,
      reviewComment: this.claim.reviewComment,
      products: this.claim.products,
      totalOverrideValue: this.totalOverrideValue || null,
    };

    LoyaltyPointsClaimsAdmin.apiLoyaltyPointsClaimsAdminIdPut(this.$props.id, payload)
      .then((res) => {
        if (res) {
          this.formSubmit = false;
          this.$message.success("Claim review successful");
          this.$router.push({ name: "RewardPointList" });
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error);
      });
  }

  handleClose() {
    this.dialogVisible = false;
    this.claim.reviewComment = "";
  }

  handleConfirm() {
    if (this.confirmText === "Send Resubmit Request" || this.confirmText === "Reject Claim") {
      if (this.claim.reviewComment != "" && this.claim.reviewComment != null) {
        this.dialogVisible = true;
        this.submitForm();
      }
    } else if (this.confirmText === "Send Claim" || this.confirmText === "Create Duplicate Claim") {
      this.dialogVisible = true;
      this.submitForm();
    } else {
      this.dialogVisible = true;
    }
  }

  feedbackRequired() {
    this.confirmText = "Send Resubmit Request";
    this.claim.loyaltyPointsClaimStatusId = "AwaitingResubmission";

    this.dialogMessage = "<h2 class='mt-0 text-center'>Please provide a reason before requesting resubmission</h2>";
    this.dialogVisible = true;
  }

  reject() {
    this.confirmText = "Reject Claim";
    this.claim.loyaltyPointsClaimStatusId = "Rejected";
    this.claim.reviewComment = "";

    this.dialogMessage = "<h2 class='mt-0 text-center'>Please provide a reason before rejecting</h2>";
    this.dialogVisible = true;
  }

  approval() {
    this.confirmText = "Send Claim";
    this.claim.loyaltyPointsClaimStatusId = "Approved";

    this.dialogMessage = "<h2 class='mt-0 text-center'>Confirm approval of the claim?</h2>";
    this.dialogVisible = true;
  }

  deleteProduct(index: number) {
    this.claim.products!.splice(index, 1);
  }

  async updateProductList(data: any) {
    const isListed = this.claim.products!.filter((prod: any) => prod.productId == data);
    if (isListed.length > 0) {
      this.$nextTick(() => {
        const anchor = this.$refs[data]! as Element;
        document.getElementById(anchor.id)!.scrollIntoView({ behavior: "smooth" });
      });
      return;
    }
    const product: ProductDdl | undefined = this.productDdl.find((prod) => prod.id == data);
    if(product) {
      this.claim.products!.push({ productSku: product.sku, productId: product.id, quantity: 1, productName: product.name, value: await this.getProductPoints(product.id) });
    } 
  }

  getProductPoints(id: string | undefined) {
    if (!id || id == "") {
      return -1;
    }
    return new Promise<number>((resolve, reject) => {
      Product.apiProductsValueGet(id, this.claim.invoiceDate || new Date().toISOString())
        .then((res) => {
          if (res.data) {
            resolve(res.data);
          } else {
            resolve(-1);
          }
        })
        .catch((error) => {
          this.isLoading = false;
          handleError(error);
        });
    });
  }

  getPointTotal() {
    if (!this.claim.products || this.claim.products.length < 1) {
      return -1;
    }
    let total: number = 0;
    Object.values(this.claim.products!).forEach((product: any) => {
      if (product.value > 0) {
        total += product.quantity! * product.value;
      }
    });
    return total;
  }

  testInvoiceNumber() {
    if (this.claim.invoiceNumber == null || this.claim.invoiceNumber == "") {
      this.duplicateClaimNumber = false;
      return;
    }
    LoyaltyPointsClaimsAdmin.apiLoyaltyPointsClaimsAdminGet(1, 99999, undefined, this.claim.invoiceNumber).then((res) => {
      if (res.data.data) {
        this.duplicateClaimNumber = res.data.data!.filter((claim) => {
          return claim.id != this.$props.id && claim.workshopName == this.claim.workshopName && claim.invoiceNumber == this.claim.invoiceNumber;
        });

        if (this.duplicateClaimNumber.length < 1) {
          this.duplicateClaimNumber = false;
        }
      }
    });
  }

  getProducts(value:string) {
    Product.apiProductsGet(1, 30, value)
      .then((res) => {
        if (res.data) {
          this.productDdl = sort(Object.values(res.data.data!).map((data: any) => {
            return { id: data.id, name: data.name + ` (${data.sku})`, value: data.point, sku: data.sku };
          }), 'name');
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.dialogVisible = false;
        handleError(error);
      });
  }

  handleProducts(open: boolean) {
    if(open) {
      this.productDdl = []
    }
  }

  filterProducts(value: string) {
    if(value.length >= 3) {
      this.getProducts(value)
    } else {
      this.productDdl = []
    }
  }
}
