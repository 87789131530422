export default function sort(array: Array<any>, prop: string| null = null) {
  // Ascending, string only
  if(array?.length > 1) {
    if(prop) {
      return array.sort((a, b) => {
        if(a[prop]?.toLowerCase() < b[prop]?.toLowerCase()){
          return -1
        }
        if(a[prop]?.toLowerCase() > b[prop]?.toLowerCase()){
          return 1
        }
        return 0
      })
    } else {
      return array.sort((a, b) => {
        if(a?.toLowerCase() < b?.toLowerCase()){
          return -1
        }
        if(a?.toLowerCase() > b?.toLowerCase()){
          return 1
        }
        return 0
      })
    }
  }
  return array;
}