
    import { Component, Vue } from 'vue-property-decorator'
    import PointClaimTable from '@/components/rewards-module/PointClaimTable.vue'
    import { Workshops } from "@/network/api"
    import { WorkshopViewModel } from "@/api-client"
    import { hasClaim } from "@/utils/applicationClaims"
    import handleError from '@/utils/handleError'
    import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import sort from '@/utils/sort'

    @Component({
        name: '',
        components: {
            PointClaimTable,
            BasicSelectorField
        }
    })
    export default class extends Vue {
        isLoading = false
        workshopDdl: Array<WorkshopViewModel> = []
        selectedId = ""
        canHandleClaim = {
            manage: false
        }

        async created() {
            this.canHandleClaim.manage = await hasClaim('ManageClaims', false);
        }

        getWorkshops(value:string) {
            Workshops.apiWorkshopsGet(1, 30, value)
                .then((res) => {
                    if (res.data) {
                        this.workshopDdl = sort(res.data.data, 'name') as WorkshopViewModel[]
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    handleError(error)
                })
        }

        handleWorkshops(open: boolean) {
            if(open) {
                this.workshopDdl = []
            }
        }

        filterWorkshops(value: string) {
            if(value.length >= 3) {
                this.getWorkshops(value)
            } else {
                this.workshopDdl = []
            }
        }
    }
