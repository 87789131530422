
import { Component, Vue } from 'vue-property-decorator'
import { Workshops, AuditLogs } from '@/network/api';
import DialogBox from "@/components/DialogBox.vue"
import Pagination from "@/components/Pagination.vue";
import { WorkshopViewModel, AuditTypes, AuditLogDto } from '@/api-client';
import { formatDate } from '@/utils/formatDate';
import sort from '@/utils/sort';
import handleError from '@/utils/handleError';
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue"

  @Component({
    name: 'AuditLogs',
    components: {
      DialogBox,
      Pagination,
      BasicSelectorField
    }
  })
  export default class extends Vue {
    tableData: Array<AuditLogDto> = []
    search: string = ''
    typeFilter: AuditTypes | "" = "";
    workshopFilter = "";
    isLoading = false
    currentPage = 1
    pageSize = 20
    totalPages = 0
    totalCount = 0
    sortDirection: 'Asc' | 'Desc' = 'Desc'
    workshopDdl: Array<WorkshopViewModel> = []
    typesDdl: Array<{id: AuditTypes, name: string}> = [
      {
        id: 'ApplicationUserRole',
        name: 'Application User Role'
      },
      {
        id: 'LoyaltyPointsTransaction',
        name: 'GBucks Transaction'
      },
      {
        id: 'User',
        name: 'User'
      },
      {
        id: 'Workshop',
        name: 'Workshop'
      },
      {
        id: 'WorkshopUser',
        name: 'Workshop User'
      }
    ]
    linkTo: { type: AuditTypes, linkName: string}[] = [
      {
        type: "User",
        linkName: 'UserDetail'
      },
      {
        type: "ApplicationUserRole",
        linkName: 'UserDetail'
      },
      {
        type: "Workshop",
        linkName: "WorkshopDetail"
      },
      {
        type: "LoyaltyPointsTransaction",
        linkName: "ViewTransactionAdmin"
      },
      {
        type: "WorkshopUser",
        linkName: "UserDetail"
      }
    ]

    created() {
      this.loadInitialData()
    }

    handleJson(value:string | null) {
      if(value) {
        let json;
        let isJson = false;

        try {
          json = JSON.parse(value);
          if(typeof json == 'object') {
            isJson = true;
          }
        } catch (error) {
          isJson = false;
        }

        if(isJson && json) {
          let convertedJson = []
          for (const [key, value] of Object.entries(json)) {
            if(value) {
              convertedJson.push(`<u>${key}</u>: ${value}`);
            }
          }
          return convertedJson.join('<br>');
        }
        return value;
      }
      return null;
    }

    handleFields(value:string | null) {
      if(value) {
        let arr = value.split(",");
        let result: string[] = []
        arr.forEach((item: string) => {
          if(item.trim()) {
            result.push(item.trim())
          }
        });
        return result.join('<br>')
      }
      return null;
    }

    appendLeadingZeroes(n:any){
      if(n <= 9){
        return "0" + n;
      }
      return n
    }

    handleDate(date:any) {
      return formatDate(date);
    }

    handleTime(date:any) {
      let hour = new Date(date).getHours();
      let minutes = new Date(date).getMinutes();
      
      return `${this.appendLeadingZeroes(hour)}:${this.appendLeadingZeroes(minutes)}`;
    }

    changeSortDirection() {
      this.sortDirection = this.sortDirection == 'Asc' ? 'Desc' : 'Asc';
      this.handleSearch()
    }

    handleSearch() {
      this.currentPage = 1;
      this.loadInitialData() 
    }

    handlePageNumberClick(page: any) {
      this.currentPage = page;
      this.loadInitialData();
    }

    handleTypeName(type:AuditTypes) {
      const match = this.typesDdl.find(item => item.id == type)
      if(match) {
        return match.name;
      } 
      return type;
    }

    routerType(row:AuditLogDto) {
      const match = this.linkTo.find(item => item.type == row.auditLogTypesId)
      if(match) {
        return { name: match.linkName, params: { id: row.typePrimaryKey.toLowerCase() }} 
      }
      return null;          
    }

    async loadInitialData(): Promise<any> {
      this.isLoading = true
      window.scrollTo({ top: 0 });
      await AuditLogs.apiAuditLogGet(this.typeFilter || undefined, this.workshopFilter, this.search, this.currentPage, this.pageSize, this.sortDirection)
        .then((res) => {
          if (res.data) {
            this.isLoading = false
            this.tableData = res.data.data as AuditLogDto[]
            this.totalPages = res.data.pageCount as number
            this.totalCount = res.data.totalCount as number
          }
        })
        .catch((error) => {
          this.isLoading = false
          handleError(error)
        })
    }

    getWorkshops(value: string) {
        Workshops.apiWorkshopsGet(1, 30, value)
            .then((res) => {
                if (res.data) {
                  this.workshopDdl = sort(res.data.data, 'name') as WorkshopViewModel[]
                }
            })
            .catch((error) => {
                this.isLoading = false
                handleError(error)
            })
    }

    handleWorkshops(open: boolean) {
    if(open) {
      this.workshopDdl = []
    }
  }

  filterWorkshops(value: string) {
    if(value.length >= 3) {
      this.getWorkshops(value)
    } else {
      this.workshopDdl = []
    }
  }
  }
