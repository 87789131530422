
    import { Component, Vue } from 'vue-property-decorator'

    @Component({
        name: 'InvoicePreview',
        props: {
            invoicePath: { default: "" }
        },
    })

    export default class extends Vue {
        get fullFilePath() {
            return `${this.uploadPath}/view/${this.$props.invoicePath}`
        }

        get uploadPath() {
            return `${process.env.VUE_APP_ROOT_API}/files`
        }

        get sizingClass() {
            return this.$props.invoicePath.indexOf(".pdf") > -1 ? "document-preview" : ""
        }
    }
