
import { Component, Vue } from "vue-property-decorator";
import { File, Workshops } from "@/network/api";
import { WorkshopViewModel } from "@/api-client";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import FileUploader from "@/components/form-items/FileUploader.vue";
import { hasClaim } from "@/utils/applicationClaims";
import handleError from "@/utils/handleError";

@Component({
  name: "WorkshopList",
  components: {
    DialogBox,
    Pagination,
    FileUploader,
  },
})
export default class extends Vue {
  tableData: WorkshopViewModel[] = [];
  search: string = "";
  isLoading = false;
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible = true;
  deletePendingIndex = "";
  currentPage = 1;
  pageSize = 20;
  totalPages = 0;
  totalCount = 0;
  fileImport: any = null;
  canHandleClaim = {
    manage: false,
  };

  async created() {
    this.canHandleClaim.manage = await hasClaim("ManageWorkshops", false);
    this.loadInitialData();
  }

  handleSearch() {
    this.currentPage = 1;
    this.loadInitialData().then(() => {
      let searchElem: any = this.$refs.searchInput;
      searchElem!.focus();
    });
  }

  handlePageNumberClick(page: any) {
    this.currentPage = page;
    this.loadInitialData();
  }

  // updateFile(file: any) {
  //   this.fileImport = file;
  //   this.isLoading = true;
  //   File.filesPost(file[0].raw)
  //     .then((res) => {
  //       this.isLoading = false;
  //       if (res?.data?.relativePath) {
  //         this.handleImport(res.data.relativePath);
  //         this.fileImport = null;
  //       }
  //     })
  //     .catch((error) => {
  //       this.isLoading = false;
  //       handleError(error)
  //     });
  // }

  // handleImport(url:string) {
  //   this.isLoading = true;
  //   Workshops.apiWorkshopsImportPost({relativeFileUrl:url})
  //   .then((res) => {
  //       if (res) {
  //         this.$message.success("Import Successful!");
  //         this.loadInitialData();
  //       }
  //       this.isLoading = false;
  //     })
  //     .catch((error) => {
  //       this.isLoading = false;
  //       handleError(error)
  //     });
  // }

  async loadInitialData() {
    this.isLoading = true;
    Workshops.apiWorkshopsGet(this.currentPage, this.pageSize, this.search)
      .then((res) => {
        if (res.status == 200) {
          this.isLoading = false;
          this.tableData = res.data.data as WorkshopViewModel[];
          this.totalPages = res.data.pageCount as number;
          this.totalCount = res.data.totalCount as number;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  deleteData(id: string) {
    Workshops.apiWorkshopsIdDelete(id)
      .then((res) => {
        if (res.status > 199 && res.status < 300) {
          this.loadInitialData();
          // this.isLoading = false
          // this.tableData = res.data
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  deleteConfirm(id: string, name: string) {
    this.confirmText = "Yes";
    this.dialogMessage = `<span>Are you sure you want to remove "${name}" from the system?</span>`;
    this.dialogVisible = true;
    this.deletePendingIndex = id;
  }

  deleteHandle() {
    this.dialogVisible = false;
    if (this.confirmText === "Yes") {
      this.deleteData(this.deletePendingIndex);
    } else {
      this.deletePendingIndex = "";
    }
  }
}
