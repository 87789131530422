
import { Component, Vue } from 'vue-property-decorator'
import { ImportDto, ImportImportErrorDto, PagedResultOfImportDto, ProductListViewModel } from "@/api-client"
import { File, Imports, Product } from '@/network/api'
import DialogBox from "@/components/DialogBox.vue"
import Pagination from "@/components/Pagination.vue"
import { hasClaim } from '@/utils/applicationClaims'
import handleError from '@/utils/handleError'
import FileUploader from "@/components/form-items/FileUploader.vue";
import DialogBoxFullscreen from "@/components/DialogBoxFullscreen.vue";

  @Component({
    name: '',
    components: {
      DialogBox,
      Pagination,
      FileUploader,
      DialogBoxFullscreen
    }
  })
  export default class extends Vue {
    tableData: Array<ProductListViewModel> = []
    search: string = ''
    isLoading = false
    fullDialogVisible: boolean = false;
    dialogVisible: boolean = false
    dialogMessage: string = ""
    confirmText: string = "Ok"
    cancelVisible = true
    deletePendingIndex = ""
    currentPage = 1
    pageSize = 20
    totalPages = 0
    totalCount = 0
    fileImport: any = null;
    importsCurrentPage = 1
    importsPageData: PagedResultOfImportDto = {
      totalCount: 0,
      pageCount: 0,
      pageSize: 0,
      pageNumber: 0,
      data: []
    }
    importsHistory: ImportDto[] = []
    canHandleClaim = {
      manage: false
    }

    get uploadPath() {
      return `${process.env.VUE_APP_ROOT_API}/files`;
    }

    async created() {
      this.canHandleClaim.manage = await hasClaim('ManageProducts', false);
      this.loadInitialData()
    }

    handleSearch() {
      this.currentPage = 1
      this.loadInitialData().then(() => {
        let searchElem: any = this.$refs.searchInput
        searchElem!.focus()
      }) 
    }

    handlePageNumberClick(page: any) {
      this.currentPage = page
      this.loadInitialData()
    }

    handleImportsPageNumberClick(page: any) {
      this.importsCurrentPage = page
      this.fetchImportData()
    }

    updateFile(file: any) {
      this.fileImport = file;
      this.isLoading = true;
      File.filesPost(file[0].raw)
        .then((res) => {
          this.isLoading = false;
          if (res?.data?.relativePath) {
            this.handleImport(res.data.relativePath);
            this.fileImport = null;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          handleError(error)
        });
    }

    handleImport(url:string) {
      this.isLoading = true;
      Product.apiProductsImportPost({fileRelativeUrl:url})
      .then((res) => {
          if (res) {
            this.$message.success("Import Successful!");
            this.loadInitialData();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          handleError(error)
        });
    }

    handleImportErrors(errors: ImportImportErrorDto[]) {
      if(errors?.length) {
        return errors.map((e, i) => `<div>${errors.length > 1 ? i + 1 + '. ' : ''}${e.message}</div>`).join("")
      }
      return "" 
    }

    fetchImportData() {
      this.isLoading = true
      Imports.apiImportGet(this.importsCurrentPage, 5)
      .then((res) => {
        if (res.data) {
          this.isLoading = false
          this.importsPageData = res.data
          this.importsHistory = this.importsPageData.data
        }
      })
      .catch((error) => {
        this.isLoading = false
        this.dialogVisible = false
        this.fullDialogVisible = false
        handleError(error)
      })
    }

    async loadInitialData(): Promise<any> {
      this.isLoading = true
      window.scrollTo({ top: 0 })
      await Product.apiProductsGet(this.currentPage, this.pageSize, this.search)
        .then((res) => {
          if (res.data) {
            this.isLoading = false
            this.tableData = res.data.data as ProductListViewModel[]
            this.totalPages = res.data.pageCount as number
            this.totalCount = res.data.totalCount as number

            this.importsCurrentPage = 1;
            this.fetchImportData()
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.dialogVisible = false
          this.fullDialogVisible = false
          handleError(error)
        })
    }

    deleteData(id: string) {
      Product.apiProductsIdDelete(id)
        .then((res) => {
          if (res.status > 199 && res.status < 300) {
            this.loadInitialData()
            this.isLoading = false
            // this.tableData = res.data
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.dialogVisible = false
          this.fullDialogVisible = false
          handleError(error)
        })
    }

    deleteConfirm(id: string, name: string) {
      this.confirmText = "Yes"
      this.dialogMessage = `<span>Are you sure you want to remove "${name}" from the system?</span>`
      this.dialogVisible = true
      this.deletePendingIndex = id
    }

    deleteHandle() {
      this.dialogVisible = false
      this.fullDialogVisible = false
      if (this.confirmText === "Yes") {
        this.deleteData(this.deletePendingIndex)
      } else {
        this.deletePendingIndex = ""
      }
    }
  }
