
import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import DialogBox from "@/components/DialogBox.vue";
import { Role, Workshops, WorkshopUsers, Advance } from "@/network/api";
import { RoleListViewModel, WorkshopStatus, WorkshopUserViewModel, WorkshopViewModel, RegisterAccountCommand } from "@/api-client";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import store from "@/store";
import { hasClaim } from "@/utils/applicationClaims";
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import handleError from "@/utils/handleError";

@Component({
  name: "WorkshopDetail",
  props: {
    id: { default: "" },
  },
  components: {
    BasicInputField,
    BasicSelectorField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
    ImageUploader
  },
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      if (to.name === "NewWorkshop") {
        vm.workshop = {
          id: "",
          name: "",
        };
      }
    });
  },
})
export default class extends Vue {
  isLoading = true
  formSubmit = false
  displayName = ""
  workshop: WorkshopViewModel = {
    id: "",
    name: "",
    workshopStatusId: WorkshopStatus.New,
    advanceUserEmail: null,
    skynamoId: null,
    relativeImageUrl: null
  }
  workshopUsers: Array<WorkshopUserViewModel> = []
	roles: Array<RoleListViewModel> = []
  dialogVisible: boolean = false
  advanceUserEmail = {
    email: "",
    isValid: true
  }
  advanceReg: boolean = false
  advanceRegLoad: boolean = false
  dialogMessage: string = ""
  confirmText: string = "Ok"
  cancelVisible = true
  workshopStatuses: { id: WorkshopStatus, name: WorkshopStatus}[] = [
    {
      id: "New",
      name: 'New'
    },
    {
      id: "Active",
      name: 'Active'
    },
    {
      id: "Inactive",
      name: 'Inactive'
    }
  ]
  localStorageWorkshopId = "";
  canHandleClaim = {
      manage: false
  }

  get isAdmin() {
    return ["WorkshopDetail", "NewWorkshop"].includes(this.$router.currentRoute.name as string)
  }

  async created() {
    this.canHandleClaim.manage = await hasClaim('ManageWorkshops', !this.isAdmin);
    this.localStorageWorkshopId = store.getters["user/workshopId"];

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });

    this.$watch("$props.id", () => {
      if (this.$props.id === "") {
        this.workshop = {
          id: "",
          name: "",
          workshopStatusId: WorkshopStatus.New
        };
      }
    });
  }

  mounted() {
    this.isLoading = false;
    this.loadInitialData();
  }

  removeImage(area: string) {
    if (area == "image") {
      this.workshop.relativeImageUrl = "";
    }
  }

  uploadImages(): Promise<any> {
    let imageUploader = this.$refs.imageUploader as ImageUploader;
    let imagePromise;
    if(imageUploader) {
      imagePromise = imageUploader!.uploadImage()
    }

    return Promise.all([imagePromise])
  }

  loadInitialData() {
    if (this.$props.id != "") {
      this.workshop.id = this.$props.id;
      this.isLoading = true;
      Workshops.apiWorkshopsIdGet(this.workshop.id as string)
        .then((res) => {
          if (res.data) {
            this.isLoading = false;
            this.dialogVisible = false;
            this.workshop = res.data;
            this.displayName = this.workshop.name as string;

            if(this.localStorageWorkshopId && (this.localStorageWorkshopId != this.workshop.id)) {
              store.dispatch("user/setWorkshopId", this.workshop.id);
            }
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.dialogVisible = false;
          handleError(error)
        });
    } else {
      this.workshop = {
        id: "",
        name: "",
        workshopStatusId: WorkshopStatus.New
      };
    }
  }

  submitForm() {
    this.formSubmit = true;

    this.uploadImages().then((results) => {
      if(results[0]?.imageUrl) {
        this.workshop.relativeImageUrl = results[0].imageUrl;
      }

      if (this.workshop.id != "") {
        this.updateWorkshop();
      } else {
        this.createWorkshop();
      }
    })
  }

  createWorkshop() {
    Workshops.apiWorkshopsPost(this.workshop)
      .then((res) => {
        if (res.data) {
          this.$message.success("Saved!");
          this.workshop.id = res.data.value;
          this.formSubmit = false;
          this.dialogVisible = false;
          this.$router.push({ name: "WorkshopDetail", params: { id: res.data.value as string } });
          this.displayName = this.workshop.name as string;
          this.clearDirtyClasses();
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  updateWorkshop() {
    Workshops.apiWorkshopsIdPut(this.workshop.id as string, this.workshop)
      .then((res) => {
        this.$message.success("Saved!");
        this.formSubmit = false;
        this.dialogVisible = false;
        this.loadInitialData();
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      if(this.isAdmin) {
        this.$router.push({ name: "WorkshopManagement" })
      }
      else {
        this.$router.push({ name: "LandingPage" })
      }
    } else {
      this.submitForm();
    }
  }

  advanceRegDialog() {
    this.advanceReg = true
    this.dialogMessage = "<h3 class='mt-0'>Please enter an email address below to assign an Advance account to this workshop.</h3>"
    this.confirmText = "Register Account"
  }

  handleAdvanceReg() {
    if (!this.advanceUserEmail.email) {
      this.advanceUserEmail.isValid = false
      return
    }

    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(this.advanceUserEmail.email)) {
      this.advanceUserEmail.isValid = false
      return
    }

    this.advanceUserEmail.isValid = true
    const account: RegisterAccountCommand = {
      email: this.advanceUserEmail.email,
      workshopId: this.workshop.id
    }
    this.advanceRegLoad = true
    Advance.apiAdvanceRegisterAccountPost(account)
      .then((res) => {
        this.advanceRegLoad = false
        this.workshop.advanceUserEmail = account.email
        this.$message.success("Saved!")
        this.advanceReg = false
        this.loadInitialData()
      })
      .catch((error) => {
        this.advanceRegLoad = false
        handleError(error)
      });
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  clearDirtyClasses() {
    let dirty = document.querySelectorAll(".dirty");

    if (dirty.length) {
      for (let item of dirty) {
        item.classList.remove("dirty");
      }
    }
  }
}
