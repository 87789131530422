
    import { Component, Vue } from 'vue-property-decorator'
import { PropType } from 'vue/types/v3-component-props';

    @Component({
        name: 'Dashboard',
        props: {
            loading: { default: false },
            isMini: { default: false },
            workshopDisplayName: { default: "" },
            total: { default: 0 },
            totalExpiring: { default: 0 },
            displayType: {
                type: String as PropType<"circle" | "box">,
                default: "box"
            }
        }
    })
    export default class extends Vue {
    }
