
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { LoyaltyPointsClaims, LoyaltyPointsClaimsAdmin } from "@/network/api"
    import { LoyaltyPointClaimListViewModel, LoyaltyPointClaimStatus } from "@/api-client"
    import DialogBox from "@/components/DialogBox.vue"
    import Pagination from "@/components/Pagination.vue";
    import { formatDate } from "@/utils/formatDate"
    import { Route } from 'vue-router'
import handleError from '@/utils/handleError';

    @Component({
        name: 'PointClaimTable',
        props: {
            isSummary: { default: false },
            isAdmin: { default: false },
            workshop: { default: "" },
            pageEntries: { default: 20 },
        },
        components: {
            DialogBox,
            Pagination
        }
    })
    export default class extends Vue {
        tableData: LoyaltyPointClaimListViewModel[] = []
        statusOptions: Array<{id: LoyaltyPointClaimStatus, name: string}> = [
            { id: LoyaltyPointClaimStatus.Pending, name: 'Pending'},
            { id: LoyaltyPointClaimStatus.Approved, name: 'Approved'},
            { id: LoyaltyPointClaimStatus.Rejected, name: 'Rejected'},
            { id: LoyaltyPointClaimStatus.AwaitingResubmission, name: 'Awaiting Resubmission'}
        ]
        status: LoyaltyPointClaimStatus | undefined = undefined
        isLoading = false
        dialogVisible: boolean = false
        dialogMessage: string = ""
        confirmText: string = "Ok"
        cancelVisible = true
        deletePendingIndex = ""
        currentPage = 1
        pageSize = this.$props.pageEntries
        totalPages = 0
        totalCount = 0
        summaryTable: boolean = this.$props.isSummary
        referenceNumberSearch: string = ""
        filterType: string | null = null
        sortDirection: 'Asc' | 'Desc' = 'Desc'

        @Watch('$props.workshop')
        private workshopUpdate() {
            this.currentPage = 1
            this.loadInitialData()
        }

        created() {
            this.loadInitialData()
        }

        changeSortDirection() {
            this.sortDirection = this.sortDirection == 'Asc' ? 'Desc' : 'Asc';
            this.handleSearch()
        }

        handleSearch() {
            this.currentPage = 1
            this.loadInitialData()
        }

        getStatusVal() {
            return this.status
        }

        getClaimRoute(statusName: string) {
            if(this.$props.isAdmin) {
                if(statusName == "Pending") {
                    return 'ReviewClaimAdmin'
                }
                else {
                    return 'ViewClaimAdmin'
                }
            }
            else {
                if(statusName == "AwaitingResubmission") {
                    return 'ClaimReupload'
                }
                else {
                    return 'ClaimDetail'
                }
            }
        }

        displayDate(date: string | null | undefined) {
            return formatDate(date)
        }

        filterHandler(id: any) {
            if (this.status != id) {
                this.status = id
                this.loadInitialData()
            }
            else {
                this.filterHandler(undefined)
            }
        }

        handlePageNumberClick(page: any) {
            this.currentPage = page
            this.loadInitialData()
        }

        loadInitialData() {
            window.scrollTo({ top: 0 })
            if (this.$props.isAdmin) {
                this.isLoading = true
                this.getAdminClaim()
            }
            else {
                if (!this.$props.workshop || this.$props.workshop == "") {
                    return
                }

                this.isLoading = true
                this.getClaim()
            }

        }

        async getAdminClaim(): Promise<any> {
            await LoyaltyPointsClaimsAdmin.apiLoyaltyPointsClaimsAdminGet(this.currentPage, this.pageSize, this.status, this.referenceNumberSearch, this.$props.workshop, this.sortDirection)
                .then((res) => {
                    if (res.data) {
                        this.isLoading = false
                        this.tableData = res.data.data as LoyaltyPointClaimListViewModel[]
                        this.totalPages = res.data.pageCount as number
                        this.totalCount = res.data.totalCount as number
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.dialogVisible = false
                    handleError(error)
                })
        }

        async getClaim(): Promise<any> {
            await LoyaltyPointsClaims.apiLoyaltyPointsClaimsGet(this.currentPage, this.pageSize, this.status, this.referenceNumberSearch, this.$props.workshop, this.sortDirection)
                .then((res) => {
                    if (res.data) {
                        this.isLoading = false
                        this.tableData = res.data.data as LoyaltyPointClaimListViewModel[]
                        this.totalPages = res.data.pageCount as number
                        this.totalCount = res.data.totalCount as number
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    this.dialogVisible = false
                    handleError(error)
                })
        }

        deleteData(id: string) {
            // LoyaltyPointsClaims.apiLoyaltyPointsClaimsIdDelete(id)
            //     .then((res) => {
            //         if (res.status > 199 && res.status < 300) {
            //             this.loadInitialData()
            //             this.isLoading = false
            //             // this.tableData = res.data
            //         }
            //     })
            //     .catch((error) => {
            //             this.isLoading = false
            //             this.dialogVisible = false
            //             handleError(error)
            //     })
        }

        deleteHandle() {
            this.dialogVisible = false
            if (this.confirmText === "Yes") {
                this.deleteData(this.deletePendingIndex)
            } else {
                this.deletePendingIndex = ""
            }
        }
    }
