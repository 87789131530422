
import { Component, Vue } from "vue-property-decorator";
import { ValidationProvider, ValidationObserver } from "vee-validate";

@Component({
  name: "BasicSwitchField",
  components: { ValidationProvider, ValidationObserver },
  props: {
    label: { default: "" },
    model: { default: "" },
    formStyle: { default: "display: flex; align-items: center" },
    switchStyle: { default: "margin-left: 15px" },
    activeValue: { default: true },
    inactiveValue: { default: false },
    isDisabled: { default: false }
  },
})
export default class extends Vue {}
