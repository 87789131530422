
    import { Component, Vue } from 'vue-property-decorator'
    import { Workshops, LoyaltyPointTransactions } from "@/network/api"
    import { WorkshopBalanceViewModel } from "@/api-client"
    import PointActivityTable from '@/components/rewards-module/PointActivityTable.vue'
    import PointClaimTable from '@/components/rewards-module/PointClaimTable.vue'
    import WorkshopPointSummary from '@/components/rewards-module/WorkshopPointSummary.vue'
    import store from '@/store'
    import handleError from '@/utils/handleError'

    @Component({
        name: 'Dashboard',
        components: {
            PointActivityTable,
            PointClaimTable,
            WorkshopPointSummary
        }
    })
    export default class extends Vue {
        isLoading = false
        workshopDisplayName: string | undefined = ""
        balanceDetails: WorkshopBalanceViewModel = {
            total: 0,
            totalExpiringSoon: 0
        }
        selectedId = null

        get workshopId() {
            return store.getters["user/workshopId"];
        }

        created() {
            this.selectedId = store.getters["user/workshopId"]
            this.loadInitialData()
        }

        async getWorkshopName(): Promise<any> {
            await Workshops.apiWorkshopsForUserGet(1, 999999, this.selectedId!)
                .then((res) => {
                    if (res.data) {
                        const workshop = res.data.data.find(item => item.id == this.workshopId)
                        if(workshop) {
                            this.workshopDisplayName = workshop.name
                        }
                    }
                })
                .catch((error) => {
                    handleError(error, true, "An error occurred trying to retrieve workshop info.")
                })
        }

        async loadInitialData(): Promise<any> {
            this.isLoading = true
            window.scrollTo({ top: 0 })

            this.getWorkshopName()
            await LoyaltyPointTransactions.apiLoyaltyPointTransactionsBalanceGet(this.selectedId!)
                .then((res) => {
                    if (res.data) {
                        this.isLoading = false
                        this.balanceDetails = res.data as WorkshopBalanceViewModel
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    handleError(error)
                })
        }
        
    }
