
    import { Component, Vue } from 'vue-property-decorator'
    import { LoyaltyPointTransactions, Workshops } from "@/network/api"
    import { CreateLoyaltyPointsManualTransactionCommand, WorkshopViewModel } from "@/api-client"
    import BasicInputField from "@/components/form-items/BasicInputField.vue"
    import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue"
    import DialogBox from "@/components/DialogBox.vue"
    import {
        ValidationProvider,
        ValidationObserver,
        extend,
        configure,
    } from "vee-validate"
import handleError from '@/utils/handleError'
import sort from '@/utils/sort'

    @Component({
        name: 'ManualPointUpdate',
        components: {
            BasicInputField,
            BasicSelectorField,
            DialogBox,
            ValidationProvider,
            ValidationObserver,
        }
    })
    export default class extends Vue {
        isLoading = false
        formSubmit = false
        workshopDdl: Array<WorkshopViewModel> = []
        selectedId = ""
        pointDisplay: number = 1
        transaction: CreateLoyaltyPointsManualTransactionCommand = {
            comment: "",
            workshopId: "",
            referenceNumber: "",
            total: 0
        }
        pointUpdateType: number | null = 1
        dialogVisible: boolean = false
        dialogMessage: string = ""
        confirmText: string = "Yes"
        cancelVisible = true

        created() {
            extend("required", {
                validate(value) {
                    return {
                        required: true,
                        valid: ["", null, undefined].indexOf(value) === -1,
                    };
                },
                computesRequired: true,
                message: "The {_field_} field is required.",
            })

            configure({
                classes: {
                    failed: "validation-error",
                    invalid: "validation-error",
                    required: "validation-error",
                },
            })
        }

        beforeSubmit() {
            const pointSign = this.pointUpdateType || 1
            this.transaction.total = this.pointDisplay * pointSign
            const workshop = this.workshopDdl.find(workshop => { return workshop.id == this.transaction.workshopId })

            this.dialogMessage = `<h2 class="mt-0">Confirm GBucks update</h2>
                An <b>${pointSign > 0 ? "increase" : "decrease"}</b> of <b>${this.pointDisplay} GBucks</b> is about to be applied to the following workshop:<br />
                <p>${workshop!.name}</p>
                Is this correct?`
            this.dialogVisible = true
        }

        async submitForm() {
            this.formSubmit = true
            this.dialogVisible = false
            await LoyaltyPointTransactions.apiLoyaltyPointTransactionsPost(this.transaction)
                .then((res) => {
                    this.$message.success("GBucks successfully updated")
                    this.formSubmit = false

                    const refs = ["workshop", "points", "comment"]
                    refs.forEach(ref => { this.flagFieldNew(ref, true) })

                    this.transaction = {
                        comment: "",
                        workshopId: "",
                        referenceNumber: "",
                        total: 0
                    }
                    this.pointDisplay = 1
                    this.pointUpdateType = null
                })
                .catch((error) => {
                    this.formSubmit = false
                    handleError(error)
                })
        }

        flagFieldNew(ref: string, isNew: boolean) {
            const element = this.$refs[ref] as Vue
            if(!element || !element.$el) {
                return
            }
            
            if(isNew) {
                element.$el.classList.add("clean-field")
            }
            else {
                element.$el.classList.remove("clean-field")
            }
        }

        get isApprovedDisabled() {
            return this.pointDisplay < 1 ||
            this.formSubmit
        }

        handleWorkshops(open: boolean) {
            if(open) {
                this.workshopDdl = []
            }
        }

        filterWorkshops(value: string) {
            if(value.length >= 3) {
                this.getWorkshops(value)
            } else {
                this.workshopDdl = []
            }
        }

        getWorkshops(value:string) {
            Workshops.apiWorkshopsGet(1, 30, value)
                .then((res) => {
                    if (res.data) {
                        this.workshopDdl = sort(res.data.data, 'name') as WorkshopViewModel[]
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    handleError(error)
                })
        }
    }
