
import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import BasicSwitchField from "@/components/form-items/BasicSwitchField.vue";
import DialogBox from "@/components/DialogBox.vue";
import { BulletinsAdmin } from "@/network/api";
import { BulletinDto, BulletinType } from "@/api-client";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import { hasClaim } from "@/utils/applicationClaims";
import { editorToolbar } from "@/utils/editorToolbar";
import BasicDateField from "@/components/form-items/BasicDateField.vue"
import ImageUploader from "@/components/form-items/ImageUploader.vue";
import handleError from "@/utils/handleError";

@Component({
  name: "BulletinDetail",
  props: {
    id: { default: "" },
  },
  components: {
    BasicInputField,
    BasicSelectorField,
		BasicDateField,
    BasicSwitchField,
    ValidationProvider,
    ValidationObserver,
    DialogBox,
		ImageUploader
  },
})
export default class extends Vue {
  loading = false;
  dirtyMavonTimer: any = null
  formSubmit = false;
  displayName = "";
  bulletin: BulletinDto = {
    id: "",
    title: "",
    thumbnailRelativeUrl: "",
    content: "",
    contentRelativeUrl: "",
    publishDate: "",
    bulletinTypesId: BulletinType.Bulletin,
  };
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible = true;
  fileNames = {
    thumbnail: null,
    content: null
  }
  contentType: "Markdown" | "FileUpload" = "Markdown";
  bulletinTypesDdl: Array<{ id: BulletinType; name: string }> = [
		{
			id: "Bulletin",
			name: "Bulletin"
		},
		{
			id: "Promotion",
			name: "Promotion"
		}
	];
	mavenToolbar = editorToolbar;
  canHandleClaim = {
    manage: false,
  };

  async created() {
    this.canHandleClaim.manage = await hasClaim("ManageBulletins", false);

    this.loadInitialData();

		let self = this;
    extend("thumbnail", {
      validate(value) {
        let uploader = self.$refs.thumbnailUploader as any;

        return {
          valid: uploader?._selectedFile || uploader?.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("content", {
      validate(value) {
        let uploader = self.$refs.contentUploader as any;

        return {
          valid: uploader?._selectedFile || uploader?.image || value,
        };
      },
      computesRequired: true,
      message: "A {_field_} is required.",
    });

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    })

    configure({
        classes: {
            failed: "validation-error",
            invalid: "validation-error",
            required: "validation-error",
        },
    })

    this.$watch("$props.id", () => {
      if (this.$props.id === "") {
        this.bulletin = {
          id: "",
					title: "",
					thumbnailRelativeUrl: "",
					content: "",
					contentRelativeUrl: "",
					publishDate: "",
					bulletinTypesId: BulletinType.Bulletin,
        };
      }
    });
  }

  clearDirtyMavonClasses() {
    let mavonDirty = document.querySelectorAll(".dirty");

    if (mavonDirty.length) {
      for (let item of mavonDirty) {
        item.classList.remove("dirty");
      }
    }
  }

	validateField (field:any) {
    const provider = this.$refs[field] as any;

    // Validate the field
    if(provider) {
      return provider!.validate();
    }     
  }

	uploadImages(): Promise<any> {
    let thumbnailUploader = this.$refs.thumbnailUploader as ImageUploader;
    let contentUploader = this.$refs.contentUploader as ImageUploader;
    let thumbPromise;
    let contentPromise;
    if(thumbnailUploader) {
      thumbPromise = thumbnailUploader!.uploadImage()
    }
    if(contentUploader) {
      contentPromise = contentUploader!.uploadImage();
    }

    return Promise.all([thumbPromise, contentPromise])
  }

  async loadInitialData() {
    if (this.$props.id) {
      this.loading = true;
      BulletinsAdmin.apiBulletinAdminIdGet(this.$props.id)
        .then((res) => {
          if (res.data) {
            this.loading = false;
            this.dialogVisible = false;
            this.bulletin = res.data;
            this.displayName = this.bulletin.title;

            if(this.bulletin.contentRelativeUrl) {
              this.contentType = "FileUpload";
            }

            this.dirtyMavonTimer = setTimeout(() => {
              this.clearDirtyMavonClasses();
            }, 1000);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.dialogVisible = false;
          handleError(error)
        });
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.$router.push({ name: "AdminPromotionsBulletins" });
    } else {
      this.submitForm();
    }
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  clearDirtyClasses() {
    let dirty = document.querySelectorAll(".dirty");

    if (dirty.length) {
      for (let item of dirty) {
        item.classList.remove("dirty");
      }
    }
  }

	removeImage(area: string) {
    if (area == "thumbnail") {
      this.bulletin.thumbnailRelativeUrl = "";
    }

    if (area == "content") {
      this.bulletin.contentRelativeUrl = "";
    }
  }

  submitForm() {
    this.formSubmit = true;

    if(this.contentType == "Markdown") {
      this.bulletin.contentRelativeUrl = null;
    } else {
      this.bulletin.content = ""
    }

		this.uploadImages().then((results) => {
      if(results[0]?.imageUrl) {
        this.bulletin.thumbnailRelativeUrl = results[0].imageUrl;
      }

      if(results[1]?.imageUrl) {
        this.bulletin.contentRelativeUrl = results[1].imageUrl;
      }

      if(this.bulletin.content == "") {
        this.bulletin.content = null;
      }

			if (this.bulletin.id) {
				this.updateItem();
			} else {
				this.createItem();
			}
		})
  }

  createItem() {
    BulletinsAdmin.apiBulletinAdminPost(this.bulletin)
      .then((res) => {
        if (res.data.value) {
          this.$message.success("Saved!");
          this.bulletin.id = res.data.value;
          this.formSubmit = false;
          this.dialogVisible = false;      
          this.displayName = this.bulletin.title as string;
          this.$router.push({ name: "ViewAdminPromotionBulletin", params: { id: res.data.value } });
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  updateItem() {
    BulletinsAdmin.apiBulletinAdminIdPut(this.bulletin.id as string, this.bulletin)
      .then((res) => {
        this.$message.success("Saved!");
        this.formSubmit = false;
        this.dialogVisible = false;
        this.loadInitialData()
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  beforeUnmount() {
    clearTimeout(this.dirtyMavonTimer);
  }
}
