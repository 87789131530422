
import { Component, Vue } from 'vue-property-decorator'
import ReturnsTable from '@/components/rewards-module/ReturnsTable.vue'

@Component({
    name: '',
    components: {
        ReturnsTable,
    }
})
export default class extends Vue {

}
