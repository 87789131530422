export const editorToolbar: any = {
  bold: true,
  italic: true,
  header: true,
  // underline: true,
  strikethrough: true,
  // mark: true,
  // superscript: true,
  // subscript: true,
  // quote: true,
  ol: true,
  ul: true,
  link: true,
  imagelink: true,
  code: false,
  table: true,
  fullscreen: true,
  readmodel: true,
  htmlcode: true,
  help: false,
  undo: true,
  redo: true,
  trash: true,
  save: false,
  navigation: false,
  // alignleft: true,
  // aligncenter: true,
  // alignright: true,
  subfield: true,
  preview: true,
}