
    import { Component, Vue } from 'vue-property-decorator'
    import PointActivityTable from '@/components/rewards-module/PointActivityTable.vue'
    import PointClaimTable from '@/components/rewards-module/PointClaimTable.vue'
    import store from '@/store'
    import { hasClaim } from "@/utils/applicationClaims"

    @Component({
        name: 'ClaimList',
        props: {
            viewtype: { default: "claim" }
        },
        components: {
            PointActivityTable,
            PointClaimTable,
        }
    })
    export default class extends Vue {
        activeTab: string = this.$props.viewtype
        selectedId = null
        canHandleClaim = {
            manage: false
        }

        async created() {
            this.canHandleClaim.manage = await hasClaim('ManageClaims', true);
            this.loadInitialData()
        }

        loadInitialData() {
            this.selectedId = store.getters["user/workshopId"]
        }
    }
