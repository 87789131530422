
  import { Component, Vue } from 'vue-property-decorator'
  import { User, Role, Workshops } from '@/network/api';
  import DialogBox from "@/components/DialogBox.vue"
  import Pagination from "@/components/Pagination.vue";
  import { UserViewModel, RoleListViewModel, WorkshopViewModel, UpdateUserCommand } from '@/api-client';
  import { hasClaim } from '@/utils/applicationClaims';
  import handleError from '@/utils/handleError';
import sort from '@/utils/sort';

  @Component({
    name: 'UserList',
    components: {
      DialogBox,
      Pagination
    }
  })
  export default class extends Vue {
    tableData: Array<UserViewModel> = []
    search: string = ''
    roleFilter = []
    workshopFilter = []
    isLoading = false
    dialogVisible: boolean = false
    dialogMessage: string = ""
    confirmText: string = "Ok"
    cancelVisible = true
    deletePendingIndex = ""
    currentPage = 1
    pageSize = 20
    totalPages = 0
    totalCount = 0
    rolesDdl: Array<RoleListViewModel> = []
    canHandleClaim = {
      manage: false
    }

    async created() {
      this.canHandleClaim.manage = await hasClaim('ManageUsers', false);
      this.loadInitialData()
    }

    async verifyUser(user: UpdateUserCommand) {
      const updateUser: UpdateUserCommand = { ...user, isVerified: true, displayName: `${user.firstName} ${user.lastName}` }
      this.updateUser(updateUser) 
    }

    async profileLock(user: UpdateUserCommand, isLocking: boolean) {
      const updateUser: UpdateUserCommand = { ...user, isEnabled: isLocking, displayName: `${user.firstName} ${user.lastName}` }
      this.updateUser(updateUser)    
    }

    async updateUser(user: UpdateUserCommand) {
      await User.apiUsersIdPut(user.id, user)
        .then((res) => {
          this.$message.success("Saved!");
          this.loadInitialData()
        })
        .catch((error) => {
          handleError(error)
        })
    }

    handleSearch() {
      this.currentPage = 1;
      this.loadInitialData() 
    }

    handlePageNumberClick(page: any) {
      this.currentPage = page;
      this.loadInitialData();
    }

    async loadInitialData(): Promise<any> {
      this.getRoles()
      // this.getWorkshop()

      this.isLoading = true
      window.scrollTo({ top: 0 });
      await User.apiUsersGet(this.currentPage, this.pageSize, this.search, this.roleFilter)
        .then((res) => {
          if (res.data) {
            this.isLoading = false
            this.tableData = res.data.data as UserViewModel[]
            this.totalPages = res.data.pageCount as number
            this.totalCount = res.data.totalCount as number
          }
        })
        .catch((error) => {
          this.isLoading = false
          this.dialogVisible = false
          handleError(error)
        })
    }

    deleteData(id: string) {
      // User.apiUserIdDelete(id)
      //   .then((res) => {
      //     if (res.status > 199 && res.status < 300) {
      //       this.loadInitialData()
      //       this.isLoading = false
      //       // this.tableData = res.data
      //     }
      //   })
      //   .catch((error) => {
      //     this.isLoading = false
      //     this.dialogVisible = false
      //     handleError(error)
      //   })
    }

    deleteConfirm(id: string, name: string) {
      this.confirmText = "Yes"
      this.dialogMessage = `<span>Are you sure you want to remove "${name}" from the system?</span>`
      this.dialogVisible = true
      this.deletePendingIndex = id
    }

    deleteHandle() {
      this.dialogVisible = false
      if (this.confirmText === "Yes") {
        this.deleteData(this.deletePendingIndex)
      } else {
        this.deletePendingIndex = ""
      }
    }

    getRoles() {
        Role.apiRolesGet(1, 99999)
            .then((res) => {
                if (res.data) {
                  const rolesArr = Object.values(res.data.data as RoleListViewModel[]).filter(data => data.isWorkshopRole == false).map((data) => { return { id: data.id as string, name: data.name as string, isWorkshopRole: data.isWorkshopRole } })
                  this.rolesDdl = sort(rolesArr, 'name')
                }
            })
            .catch((error) => {
                this.isLoading = false
                this.dialogVisible = false
                handleError(error)
            })
    }
  }
