
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "DialogBoxFullscreen",
  props: {
    dialogVisible: {default: false},
    showClose: {default: true},
    showConfirm: {default: false},
    overflowY: {default: false},
    title: {default: ""},
    message: {default: ""},
  },
})
export default class extends Vue {
  
}
