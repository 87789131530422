
import { Component, Vue } from "vue-property-decorator";
import { LoyaltyPointsTranactionReturnsAdmin } from "@/network/api";
import { LoyaltyPointsTranactionReturnDto, ReturnsStatus } from "@/api-client";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicDateField from "@/components/form-items/BasicDateField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import DialogBox from "@/components/DialogBox.vue";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import InvoicePreview from "@/components/rewards-module/InvoicePreview.vue";
import { hasClaim } from "@/utils/applicationClaims";
import { formatDate } from "@/utils/formatDate";
import handleError from "@/utils/handleError";

@Component({
  name: "ReviewClaim",
  props: {
    id: { default: "" },
  },
  components: {
    InvoicePreview,
    BasicInputField,
    BasicDateField,
    BasicSelectorField,
    DialogBox,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class extends Vue {
  isLoading = true;
  formSubmit = false;
  returnStatusesDdl: Array<{ id: ReturnsStatus; name: string }> = [
    // {
    // 	id: 'Pending',
    // 	name: 'Pending'
    // },
    {
      id: "Approved",
      name: "Approved",
    },
    {
      id: "Rejected",
      name: "Rejected",
    },
  ];
  originalReturnStatus: ReturnsStatus = ReturnsStatus.Pending;
  rewardReturn: LoyaltyPointsTranactionReturnDto = {
    id: "",
    loyaltyPointsUpdateId: "",
    returnReason: "",
    workshopId: "",
    returnsStatusId: ReturnsStatus.Pending,
    skynamoId: "",
    submittedByUserId: "",
    workshopName: "",
    submittedByUserDisplayName: "",
    createdDate: "",
    taskId: 0,
  };
  canHandleClaim = {
      manage: false
  }

  async created() {
    this.canHandleClaim.manage = await hasClaim("ManageReturns", false);

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });
  }

  mounted() {
    this.isLoading = false;
    this.loadInitialData();
  }

	displayDate(date: string | null | undefined) {
    return formatDate(date);
  }

  async loadInitialData() {
    if (this.$props.id != "") {
      this.isLoading = true;
      await LoyaltyPointsTranactionReturnsAdmin.apiLoyaltyPointsReturnsAdminIdGet(this.$props.id)
        .then((res) => {
          if (res.data) {
            this.isLoading = false;
            this.rewardReturn = res.data;
            this.originalReturnStatus = this.rewardReturn.returnsStatusId as ReturnsStatus;
          }
        })
        .catch((error) => {
          this.isLoading = false;
          handleError(error)
        });
      this.rewardReturn.id = this.$props.id;
    }
  }

  submitForm() {
    this.formSubmit = true;
    LoyaltyPointsTranactionReturnsAdmin.apiLoyaltyPointsReturnsAdminIdPut(this.$props.id, this.rewardReturn)
      .then((res) => {
        if (res) {
          this.formSubmit = false;
          this.$message.success("Return update successful");
          // this.$router.push({ name: "AdminRewardsReturns" });
        }
      })
      .catch((error) => {
        this.formSubmit = false;
        handleError(error)
      });
  }
}
