
    import { Component, Vue } from 'vue-property-decorator'
    import BasicInputField from "@/components/form-items/BasicInputField.vue"
    import DialogBox from "@/components/DialogBox.vue"
    import { Product } from "@/network/api"
    import {
        ValidationProvider,
        ValidationObserver,
        extend,
        configure,
    } from "vee-validate"
    import { formatDate } from "@/utils/formatDate"
import { hasClaim } from '@/utils/applicationClaims'
import handleError from '@/utils/handleError'

    @Component({
        name: '',
        props: {
            id: { default: "" }
        },
        components: {
            BasicInputField,
            DialogBox,
            ValidationProvider,
            ValidationObserver,
        }
    })
    export default class extends Vue {
        isLoading = false
        formSubmit = false
        displayName = ""
        product: any = {//ProductViewModel
            id: '',
            name: '',
            sku: '',
            productLoyaltyPointValues: []
        }
        loyaltyPointEditObj: Array<any> = []
        loyaltyPointIndex: number = -1
        dialogVisible: boolean = false
        dialogMessage: string = ""
        confirmText: string = "Ok"
        cancelVisible = true
        canHandleClaim = {
            manage: false
        }

        async created() {
            this.canHandleClaim.manage = await hasClaim('ManageProducts', false);
            extend("required", {
                validate(value) {
                    return {
                        required: true,
                        valid: ["", null, undefined].indexOf(value) === -1,
                    }
                },
                computesRequired: true,
                message: "The {_field_} field is required.",
            })

            configure({
                classes: {
                    failed: "validation-error",
                    invalid: "validation-error",
                    required: "validation-error",
                },
            })

            this.$watch("$props.id", () => {
                if (this.$props.id === "") {
                    this.product = {
                        id: '',
                        name: '',
                        sku: '',
                        productLoyaltyPointValues: []
                    }
                }
            })
        }

        mounted() {
            this.isLoading = false
            this.loadInitialData()
        }

        displayDate(date: string | null | undefined) {
            return formatDate(date)
        }

        loadInitialData() {
            if (this.$props.id != "") {
                this.product.id = this.$props.id
                this.isLoading = true
                Product.apiProductsIdGet(this.product.id)
                    .then((res) => {
                        if (res.data) {
                            this.isLoading = false
                            this.dialogVisible = false
                            this.product = res.data
                            this.displayName = this.product.name
                            this.loyaltyPointEditObj = this.getDeepCopy(this.product.productLoyaltyPointValues)
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        this.dialogVisible = false
                        handleError(error)
                    })
            }
            else {
                this.product = {
                    id: null,
                    name: null,
                    sku: null,
                    productLoyaltyPointValues: []
                }
            }
            Object.values(this.product.productLoyaltyPointValues).forEach((value: any, index: number) => {
                this.loyaltyPointEditObj.push(this.getDeepCopy(value))
            })
        }

        submitForm() {
            this.formSubmit = true
            if(this.product.id != null) {
                this.updateProduct()
            }
            else {
                this.createProduct()
            }
        }

        createProduct() {
            Product.apiProductsPost({sku: this.product.sku, name: this.product.name, productLoyaltyPointValues: this.product.productLoyaltyPointValues})
                .then((res) => {
                    if(res.data.value) {
                        this.$message.success("Saved!")
                        this.product.id = res.data.value
                        this.formSubmit = false
                        this.dialogVisible = false
                        this.$router.push({ name: 'RewardProductDetail', params: { id: res.data.value } })
                        this.displayName = this.product.name
                        this.clearDirtyClasses()
                    }
                })
                .catch((error) => {
                    this.formSubmit = false
                    this.dialogVisible = false
                    handleError(error)
                })
        }

        updateProduct() {
            Product.apiProductsIdPut(this.product.id, this.product)
                .then((res) => {
                    this.$message.success("Saved!")
                    this.formSubmit = false
                    this.dialogVisible = false
                    this.loadInitialData()
                })
                .catch((error) => {
                    this.formSubmit = false
                    this.dialogVisible = false
                    handleError(error)
                })
        }

        handleConfirm() {
            if (this.confirmText === "Yes") {
                this.$router.push({ name: "RewardProductList"})
            } else {
                this.submitForm()
            }
        }

        beforeBack() {
            let isDirty = document.querySelectorAll(".dirty")
            this.confirmText = "Yes"

            if (isDirty.length > 0) {
                this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>"
                this.dialogVisible = true
            } else {
                this.handleConfirm()
            }
        }

        clearDirtyClasses() {
            let dirty = document.querySelectorAll(".dirty")

            if (dirty.length) {
                for (let item of dirty) {
                    item.classList.remove("dirty")
                }
            }
        }

        negativeValueTest(index: number) {
            if(!this.loyaltyPointEditObj[index].value || this.loyaltyPointEditObj[index].value < 0) {
                this.loyaltyPointEditObj[index].value = 0
            }
        }

        disabledStartDate(date: any, end: any) {
            if (end) {
                return date.getTime() >= end
            } else {
                return false
            }
        }

        disabledEndDate(date: any, start: any) {
            if (start) {
                return date.getTime() < start
            } else {
                return false
            }
        }

        newPointValueEntry() {
            this.product.productLoyaltyPointValues.push(
                {
                    value: 0,
                    startDate: null,
                    endDate: null,
                    id: null
                }
            )
            this.loyaltyPointEditObj.push(
                {
                    value: 1,
                    startDate: null,
                    endDate: null,
                    id: null
                }
            )
            this.loyaltyPointIndex = this.loyaltyPointEditObj.length - 1
        }

        editPointValue(index: number) {
            this.valueTransfer(index, false)
            this.loyaltyPointIndex = index
        }

        savePointValue(index: number) {
            document.getElementById("PointTable")?.classList.add("dirty")
            this.valueTransfer(index, true)
            this.loyaltyPointIndex = -1
        }

        cancelPointValue() {
            if(this.product.productLoyaltyPointValues[this.loyaltyPointIndex].value == 0) {
                this.deletePointValue(this.loyaltyPointIndex)
            }
            this.loyaltyPointIndex = -1
        }

        deletePointValue(index: number) {
            this.product.productLoyaltyPointValues.splice(index, 1)
            this.loyaltyPointEditObj.splice(index, 1)
        }

        getDeepCopy(obj: any) {
            return JSON.parse(JSON.stringify(obj))
        }

        valueTransfer(index: number, isSave: boolean) {
            if(isSave) {
                return Object.keys(this.loyaltyPointEditObj[index]).forEach((key: string) => {
                    this.product.productLoyaltyPointValues[index][key] = this.loyaltyPointEditObj[index][key]
                })
            }
            else {
                return Object.keys(this.product.productLoyaltyPointValues[index]).forEach(key => {
                    this.loyaltyPointEditObj[index][key] = this.product.productLoyaltyPointValues[index][key]
                })
            }
        }
    }
