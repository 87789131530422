export function formatDate(date: string | null | undefined) {
    function appendLeadingZeroes(n: number){
      if(n <= 9){
        return "0" + n;
      }
      return n
    }

    if(!date) {
      return '';
    }

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const dateToFormat = new Date(date)
    const formattedDate = appendLeadingZeroes(dateToFormat.getDate()) + " " + months[dateToFormat.getMonth()] + " " + dateToFormat.getFullYear()
    return formattedDate;
  }