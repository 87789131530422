
    import { Component, Vue, Watch } from 'vue-property-decorator'
    import { LoyaltyPointsTranactionReturns, LoyaltyPointTransactions } from "@/network/api"
    import { LoyaltyPointTransactionListViewModel, SubmitLoyaltyPointsReturnCommand } from "@/api-client"
    import Pagination from "@/components/Pagination.vue";
    import { formatDate } from "@/utils/formatDate";
    import { hasClaim } from "@/utils/applicationClaims";
    import DialogBox from "@/components/DialogBox.vue"
		import store from '@/store';
    import BasicInputField from "@/components/form-items/BasicInputField.vue"
import handleError from '@/utils/handleError';
    
    @Component({
        name: 'PointActivityTable',
        props: {
            isSummary: { default: false },
            workshop: { default: "" },
            pageEntries: { default: 20 },
            createDate: { },
            expireDate: { },
            isWorkshopReport: { default: true }
        },
        components: {
            Pagination,
						DialogBox,
						BasicInputField
        }
    })
    export default class extends Vue {
        tableData: Array<LoyaltyPointTransactionListViewModel> = []
        status: number = 0
        isLoading = false
        deletePendingIndex = ""
        currentPage = 1
        pageSize = this.$props.pageEntries
        totalPages = 0
        totalCount = 0
        summaryTable: boolean = this.$props.isSummary
        referenceNumberSearch: string = ""
        canHandleReturns = {
            view: false,
            manage: false
        };
        dialogVisible = false;
        returnItemData: SubmitLoyaltyPointsReturnCommand = {
            loyaltyPointsUpdateId: "",
            returnReason: "",
            workshopId: ""
        }
        returnSuccess = false;

				get isAdmin() {
            return this.$router.currentRoute.name == "AdminRewardsReport"
        }

        @Watch('$props.workshop')
        private workshopUpdate() {
            this.currentPage = 1
            this.loadInitialData()
        }
        @Watch('$props.createDate')
        private createDateUpdate() {
            this.currentPage = 1
            this.loadInitialData()
        }
        @Watch('$props.expireDate')
        private expireDateUpdate() {
            this.currentPage = 1
            this.loadInitialData()
        }

        async created () {
            this.canHandleReturns.view = await hasClaim('ViewReturns', !this.isAdmin);
            this.canHandleReturns.manage = await hasClaim('ManageReturns', !this.isAdmin);
            this.loadInitialData()
        }

        routerType(instance: LoyaltyPointTransactionListViewModel) {
            let queries = {};
            const isRewardsReport = ['RewardsReport', 'AdminRewardsReport'].includes(this.$route.name as string);

            if(!this.$props.isWorkshopReport) {
                queries = {
                    workshop: this.$props.workshop || undefined
                }
            }
            if(instance.isClaim) {
                return { name: !this.$props.isWorkshopReport ? "ViewClaimAdmin" : "ClaimDetail", params: { id: instance.loyaltyPointsUpdateId }, query: {viewtype: isRewardsReport ? 'report' : undefined, ...queries}} 
            }
            else {
                return { name: !this.$props.isWorkshopReport ? "ViewTransactionAdmin" : "TransactionDetail", params: { id: instance.loyaltyPointsUpdateId }, query: {viewtype: isRewardsReport ? 'report' : undefined, ...queries}} 
            }
        }

        displayDate(date: string | null | undefined) {
            return formatDate(date)
        }

        handleSearch() {
            this.currentPage = 1
            this.loadInitialData().then(() => {
                let searchElem: any = this.$refs.searchInput
                searchElem!.focus()   
            }) 
        }

        handlePageNumberClick(page: any) {
            this.currentPage = page
            this.loadInitialData()
        }

        requestReturn() {
					this.isLoading = true
					LoyaltyPointsTranactionReturns.apiLoyaltyPointsReturnPost(this.returnItemData)
					.then((res) => {
							if (res.data) {
									this.returnSuccess = true;
							}
							this.isLoading = false;
					})
					.catch((error) => {
							this.isLoading = false
							handleError(error)
					})
        }

				closeRequestReturn() {
					this.returnItemData = {
						loyaltyPointsUpdateId: "",
						returnReason: "",
						workshopId: ""
					}

					if(this.returnSuccess) {
						this.loadInitialData()
					}

					this.returnSuccess = false;
					this.dialogVisible = false;
				}

				initiateRequestReturn(item:LoyaltyPointTransactionListViewModel) {
					const workshopId = store.getters["user/workshopId"];
					this.returnItemData = {
						loyaltyPointsUpdateId: item.loyaltyPointsUpdateId as string,
						workshopId: workshopId,
						returnReason: ""
					}
					this.dialogVisible = true;
				}

        async loadInitialData(): Promise<any> {
            this.isLoading = true
            window.scrollTo({ top: 0 })
            if (!this.$props.workshop || this.$props.workshop == "") {
                return
            }
            const create = this.$props.createDate || { startDate: "", endDate: "" }
            const expire = this.$props.expireDate || { startDate: "", endDate: "" }
            await LoyaltyPointTransactions.apiLoyaltyPointTransactionsGet(this.currentPage, this.pageSize, this.referenceNumberSearch, this.$props.workshop, 
                create.startDate, create.endDate,
                expire.startDate, expire.endDate)
                .then((res) => {
                    if (res.data) {
                        this.isLoading = false
                        this.tableData = res.data.data as LoyaltyPointTransactionListViewModel[]
                        this.totalPages = res.data.pageCount as number
                        this.totalCount = res.data.totalCount as number
                    }
                })
                .catch((error) => {
                    this.isLoading = false
                    handleError(error)
                })
        }
    }
