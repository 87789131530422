
    import { Component, Vue } from 'vue-property-decorator'
    import { LoyaltyPointTransactions, User } from "@/network/api"
    import { LoyaltyPointsManualTransactionViewModel, PurchasedProductViewModel, ReturnsStatus, UserViewModel } from "@/api-client"
    import store from '@/store'
import handleError from '@/utils/handleError'

    @Component({
        name: 'TransactionDetail',
        props: {
            id: { default: "" }
        },
        components: {
        }
    })
    export default class extends Vue {
        isLoading = false
        transaction: LoyaltyPointsManualTransactionViewModel = {
            id: "",
            comment: "",
            workshopId: "",
            submittedByUserId: "",
            referenceNumber: "",
            totalValue: 0,
            workshopName: "",
            submittedByUserDisplayName: "",
            loyaltyPointsUpdateLoyaltyPointUpdatePurchasedProducts: [
                {
                quantity: 0,
                value: 0,
                sku: "",
                name: ""
                }
            ],
            invoiceHtml: "",
            loyaltyPointsTranactionReturnsStatusId: ReturnsStatus.Pending,
            canReturn: false
        }
        fromWorkshop: string | null = null
        fromReport = false;
        workshopId = "";
        activeTab: string = "details";

        get isWorkshop() {
            return this.$route.name === 'TransactionDetail';
        }

        get hasReturnStatus() {
            return
        }
        
        created() {
            this.workshopId = store.getters["user/workshopId"];
            this.loadInitialData()

            if(this.$route.query?.viewtype == 'report') {
                this.fromReport = true;
            }

            if(this.$route.query?.workshop) {
                this.fromWorkshop = this.$route.query?.workshop as string;
            }
        }

        async loadInitialData(): Promise<any> {
            this.isLoading = true
            window.scrollTo({ top: 0 })
            if (this.$props.id != "") {
                await LoyaltyPointTransactions.apiLoyaltyPointTransactionsIdGet(this.$props.id)
                    .then((res) => {
                        if (res.data) {
                            this.isLoading = false
                            this.transaction = res.data

                            if(this.workshopId && (this.workshopId != this.transaction.workshopId)) {
                                store.dispatch("user/setWorkshopId", this.transaction.workshopId);
                            }
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        handleError(error)
                    })
            }
        }
    }
