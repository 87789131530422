
import { Component, Vue } from "vue-property-decorator";
import BasicInputField from "@/components/form-items/BasicInputField.vue";
import BasicSelectorField from "@/components/form-items/BasicSelectorField.vue";
import DialogBox from "@/components/DialogBox.vue";
import { WorkshopUsers } from "@/network/api";
import { RoleListViewModel, UpdateWorkshopUserCommand, WorkshopUserViewModel } from "@/api-client";
import { Role } from "@/network/api";
import { ValidationProvider, ValidationObserver, extend, configure } from "vee-validate";
import store from '@/store'
import { hasClaim } from "@/utils/applicationClaims";
import handleError from "@/utils/handleError";

@Component({
  name: "UserDetail",
  props: {
    id: { default: "" },
  },
  components: {
    BasicInputField,
    BasicSelectorField,
    ValidationProvider,
    ValidationObserver,
    DialogBox,
  },
})
export default class extends Vue {
  isLoading = true;
  formSubmit = false;
  displayName = "";
  user: WorkshopUserViewModel = {
    id: "",
    displayName: "",
    email: "",
    roleIds: [],
    cellNumber: ""
  };
  workshopRolesEditObj: Array<any> = [];
  rolesDdl: Array<RoleListViewModel> = [];
  dialogVisible: boolean = false;
  dialogMessage: string = "";
  confirmText: string = "Ok";
  cancelVisible = true;
  canHandleClaim = {
      manage: false
  }

  async created() {
    this.canHandleClaim.manage = await hasClaim('ManageUsers', true);

    extend("required", {
      validate(value) {
        return {
          required: true,
          valid: ["", null, undefined].indexOf(value) === -1,
        };
      },
      computesRequired: true,
      message: "The {_field_} field is required.",
    });

    configure({
      classes: {
        failed: "validation-error",
        invalid: "validation-error",
        required: "validation-error",
      },
    });

    this.$watch("$props.id", () => {
      if (this.$props.id === "") {
        this.user = {
          id: "",
          displayName: "",
          email: "",
          roleIds: [],
          cellNumber: ""
        };
      }
    });
  }

  mounted() {
    this.isLoading = false;
    this.loadInitialData();
  }

  get workshopId() {
    return store.getters["user/workshopId"];
  }

  loadInitialData() {
    this.getRoles();

    if (this.$props.id != "") {
      this.isLoading = true;
      WorkshopUsers.apiWorkshopusersIdGet(this.$props.id, this.workshopId)
        .then((res) => {
          if (res.data) {
            this.isLoading = false;
            this.dialogVisible = false;
            this.user = res.data;
            this.displayName = this.user.displayName || "";
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.dialogVisible = false;
          handleError(error)
        });
    }
  }

  handleConfirm() {
    if (this.confirmText === "Yes") {
      this.$router.push({ name: "WorkshopUsers" });
    } else {
      this.submitForm();
    }
  }

  beforeBack() {
    let isDirty = document.querySelectorAll(".dirty");
    this.confirmText = "Yes";

    if (isDirty.length > 0) {
      this.dialogMessage = "<span>Are you sure you want to leave this page?<br>Any unsaved changes will be lost.</span>";
      this.dialogVisible = true;
    } else {
      this.handleConfirm();
    }
  }

  clearDirtyClasses() {
    let dirty = document.querySelectorAll(".dirty");

    if (dirty.length) {
      for (let item of dirty) {
        item.classList.remove("dirty");
      }
    }
  }

  submitForm() {
    this.formSubmit = true;
    if (this.user.id != "") {
      this.updateUser();
    }
  }

  updateUser() {
    const payload: UpdateWorkshopUserCommand = {
      workshopId: this.workshopId,
      roleIds: this.user.roleIds,
      userId: this.user.id,
    };
    WorkshopUsers.apiWorkshopusersPut(payload)
      .then((res) => {
        this.$message.success("Saved!");
        this.formSubmit = false;
        this.dialogVisible = false;
        this.loadInitialData();
      })
      .catch((error) => {
        this.formSubmit = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }

  getRoles() {
    Role.apiRolesGet(1, 99999, undefined, true)
      .then((res) => {
        if (res.data) {
          this.rolesDdl = res.data.data as RoleListViewModel[];
        }
      })
      .catch((error) => {
        this.isLoading = false;
        this.dialogVisible = false;
        handleError(error)
      });
  }
}
