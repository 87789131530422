
import { Component, Vue } from "vue-property-decorator";
import Logo from "@/components/Logo/index.vue";
import { Bulletins, File } from "@/network/api";
import { BulletinDto, BulletinType } from "@/api-client";
import { formatDate } from "@/utils/formatDate";
import VueMarkdown from 'vue-markdown-render';
import handleError from "@/utils/handleError";
import getFileExtension from "@/utils/getFileExtension";


@Component({
  name: "ViewBulletin",
  components: {
    Logo,
    VueMarkdown
  },
  props: {
    id: { type: String, required: true },
  },
})
export default class extends Vue {
  loading = false;
  item: BulletinDto = {
    id: "",
    title: "",
    thumbnailRelativeUrl: "",
    content: "",
    contentRelativeUrl: "",
    publishDate: "",
    bulletinTypesId: BulletinType.Bulletin,
  };
  validImgExtensions = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp']
  markdownOptions = {
    breaks: true,
    linkify: true
  }

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }
  
  get fileExtension() {
    if(this.item.contentRelativeUrl) {
      return getFileExtension(this.item.contentRelativeUrl)
    }
    return ""
  }

  created() {
    this.loadInitialData();
  }

  handleDate(date: any) {
    return formatDate(date);
  }

  loadInitialData() {
    this.loading = true;
    Bulletins.apiBulletinIdGet(this.$props.id)
      .then((res) => {
        if (res.data) {
          this.loading = false;
          this.item = res.data;
        }
      })
      .catch((error) => {
        this.loading = false;
        handleError(error)
      })
  }
}
