
import { Component, Vue } from "vue-property-decorator";
import Pagination from "@/components/Pagination.vue";
import Logo from "@/components/Logo/index.vue";
import { Bulletins } from "@/network/api";
import { BulletinDto } from "@/api-client";
import { formatDate } from "@/utils/formatDate";
import handleError from "@/utils/handleError";

@Component({
  name: "Bulletins",
  components: {
    Logo,
    Pagination,
  },
})
export default class extends Vue {
  loading = false;
  items: BulletinDto[] = [];
  currentPage = 1;
  pageSize = 12;
  totalPages = 1;
  totalCount = 1;

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  created() {
    this.loadInitialData();
  }

  handleDate(date:any) {
    return formatDate(date);
  }

  handlePageNumberClick(page: any) {
    this.currentPage = page;
    this.loadInitialData();
  }

  loadInitialData() {
    this.loading = true;
    Bulletins.apiBulletinGet(this.$route.name == "Promotions" ? "Promotion" : "Bulletin", this.currentPage, this.pageSize)
      .then((res) => {
        if (res.data) {
          this.loading = false;
          this.items = res.data.data;
          this.totalPages = res.data.pageCount as number;
          this.totalCount = res.data.totalCount as number;
        }
      })
      .catch((error) => {
        this.loading = false;
        handleError(error)
      });
  }
}
