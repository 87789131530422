
import { Component, Vue } from "vue-property-decorator";
import { User, WorkshopUsers } from "@/network/api";
import DialogBox from "@/components/DialogBox.vue";
import Pagination from "@/components/Pagination.vue";
import { WorkshopUserViewModel } from "@/api-client";
import store from '@/store'
import handleError from "@/utils/handleError";

@Component({
  name: "WorkshopUsersList",
  components: {
    DialogBox,
    Pagination,
  },
})
export default class extends Vue {
  tableData: WorkshopUserViewModel[] = [];
  search: string = "";
  roleFilter: string[] = [];
  isLoading = false;
  currentPage = 1;
  pageSize = 3;
  totalPages = 0;
  totalCount = 0;

  created() {
    this.loadInitialData();
  }

  get workshopId() {
      return store.getters["user/workshopId"];
  }

  handleSearch() {
    this.currentPage = 1;
    this.loadInitialData().then(() => {
      let searchElem: any = this.$refs.searchInput;
      searchElem!.focus();
    });
  }

  handlePageNumberClick(page: any) {
    this.currentPage = page;
    this.loadInitialData();
  }

  async loadInitialData(): Promise<any> {
    this.isLoading = true;
    window.scrollTo({ top: 0 });
    await WorkshopUsers.apiWorkshopusersGet(this.currentPage, this.pageSize, this.search, this.roleFilter, this.workshopId,)
      .then((res) => {
        if (res.data) {
          this.isLoading = false;
          this.tableData = res.data.data as WorkshopUserViewModel[];
          this.totalPages = res.data.pageCount as number;
          this.totalCount = res.data.totalCount as number;
        }
      })
      .catch((error) => {
        this.isLoading = false;
        handleError(error)
      });
  }
}
