
import { Component, Vue } from "vue-property-decorator";
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate";

@Component({
  name: "BasicDateField",
  components: { ValidationProvider, ValidationObserver },
  props: {
    label: { default: '' },
    format: { default: 'dd MMM yyyy' },
    model: { default: '' },
    placeholder: { default: 'Select date' },
    span: { default: 12 },
    sm: { default: 12 },
    xs: { default: 24 },
    name: { default: '' },
    rules: { default: '' },
    mode: { default: 'eager' },
    type: { default: 'datetime' },
    size: { default: '' },
    maxlength: { default: null },
    isDisabled: { default: false },
    pickerOptions: { default: null }
  }
})
export default class extends Vue {}
