
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { ElUpload } from "element-ui/types/upload";

const AppProps = Vue.extend({
  props: {
    image: { type: [Object, String], default: null },
    event: {
      type: Function,
    },
    label: {
      type: String,
      default: "",
    },
    area: {
      type: String,
      default: "",
    },
    uploadCriteria: {
      type: Function,
      default: null,
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    allowVideoLink: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: true,
    }
  },
});

@Component({
  name: "ImageUploader",
  model: {
    prop: "image",
    event: "removed",
  },
})
export default class extends AppProps {
  showThumbnail: boolean = true;
  disabled: boolean = true;
  validImgExtensions = ['png', 'jpg', 'jpeg', 'svg', 'gif', 'bmp', 'webp']
  validVideoUrl = ['player.vimeo.com']
  _selectedFile: string | null = null;
  videoUrl: string | null = null
  useVideoUrl = false
  resolveUpload: Function | null = null;
  errorUpload: Function | null = null;
  originalImagePath: any = null;
  dialogImageUrl= ''
  dialogVisible= false
  fileName: string | null = null;

  canPreview = true

  get uploadPath() {
    return `${process.env.VUE_APP_ROOT_API}/files`;
  }

  get showTextArea() {
    return this.allowVideoLink && this.useVideoUrl && (!this.image || (this.image && this.imagePath?.includes(this.validVideoUrl)))
  }

  get imagePath() {
    return (
      this.image &&
      `${this.uploadPath}/${
        this.image.imageUrl ? this.image.imageUrl : this.image
      }`
    );
  }

  get selectedFile() {
    return this._selectedFile;
  }

  get name() {
    if(this.fileName) {
      return this.fileName
    } else if(this.image) {
      const nameArr = this.image.split("/");
      return nameArr[nameArr.length - 1];
    }
    return ""
  }

  setFallbackImage({target}:any) {
    if(target) {
      target.src = '/img/fallback-file-image.png'
      this.canPreview = false
    }
  }

  handlePreview(url:string) {
    if(this.canPreview) {
      this.dialogImageUrl = url;
      this.dialogVisible = true;
    } else {
      window.open(this.imagePath, "_blank")
    }
  }

  copyPath(path: any) {
    navigator.clipboard.writeText(`${path}`)
    this.$message.success('Path copied.')
  }

  created() {
    if(this.allowVideoLink && (this.imagePath?.includes(this.validVideoUrl))) {
      this.videoUrl = this.image.imageUrl ? this.image.imageUrl : this.image;
      this.useVideoUrl = true;
    }
  }

  handleChange(file: any, fileList: Array<any>) {
    console.log("on change", file);

    this.showThumbnail = false;
    this._selectedFile = file;
    if (file) {
      this.showThumbnail = false;
      this.fileName = file.name || null
    }
    this.$emit('validate')
  }

  handleVideoUrl() {
    this.$emit("success", this.videoUrl);
  }

  handleSuccess(res: any, _rawFile: any) {
    if (!this.autoUpload) {
      this.resolveUpload!({ imageUrl: res.relativePath });
    }
    this.showThumbnail = false;
    this.$emit("success", res.relativePath);
  }

  handleError(err: any, _rawFile: any) {
    this.errorUpload!(err);
    this.showThumbnail = false;
  }

  handleRemove() {
    console.log("remove");
    this._selectedFile = null;
    this.videoUrl = null;
    //this.$emit("removed", this.area);
    setTimeout(() => {
      this.showThumbnail = true;
    }, 400);
    this.fileName = null
  }

  removeUploadedImage(data: any) {
    console.log("remove uploaded");
    this._selectedFile = null;
    this.videoUrl = null;
    this.$emit("removed", this.area);
    this.showThumbnail = true;
    this.fileName = null
  }

  async uploadImage() {
    let uploader = this.$refs.upload as ElUpload;
    return new Promise<void>((resolve, reject) => {
      this.resolveUpload = resolve;
      this.errorUpload = reject;
      if (uploader && this._selectedFile) {
        uploader!.submit();
      } else {
        console.log("in upload image", this.image);
        if (this.image) {
          this.resolveUpload({ imageUrl: this.image.imageUrl });
        } else {
          this.resolveUpload({ imageUrl: null });
        }
      }
    });
  }
}
